import React from 'react';
import styled from 'styled-components';
const appConfig = require('../../../../src/data.json');

const StyledButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 16px 0;
  border-radius: 12px;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.3s ease;
  min-width: 96px;

  background-color: ${({primary}) =>
    primary ? appConfig.designsTheme.primary : 'white'};
  color: ${({primary}) => (primary ? 'white' : appConfig.designsTheme.primary)};
  border: ${({primary}) =>
    primary ? 'none' : `1px solid ${appConfig.designsTheme.primary}`};

  &:hover {
    background-color: ${({primary}) => (primary ? '#e64a19' : '#fff5f2')};
  }
`;

const Button = ({primary = false, children, ...props}) => {
  return (
    <StyledButton primary={primary} {...props}>
      {children}
    </StyledButton>
  );
};

export default Button;
