import {loadImage} from './imageLoader';

/**
 * 雙線性扭曲 (bilinear warp) 處理函式
 * @param {Object} wo - 物件，包含 x1, y1, x2, y2, x3, y3, x4, y4, width, height, image_src 等
 * @returns {Promise<string>} 回傳處理後的 Base64 圖片
 */
export async function bilinearWarpSystem(wo, options = {}) {
  console.log(options);
  const {
    doSecondDrawing = false,
    offsetX = -10,
    offsetY = -7,
    subdivHeightThreshold = 300,
    subdivRatio = 1.5,
    subs = 1,
  } = options;
  console.log('開始處理雙線性扭曲，輸入參數:', wo);
  if (!wo?.image_src) {
    throw new Error('需要提供圖片來源');
  }

  try {
    // 建立暫時的 canvas
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    canvas.width = wo.width;
    canvas.height = wo.height;
    console.log('Canvas 尺寸:', {width: canvas.width, height: canvas.height});
    // 載入圖片
    const image = wo.image_src;
    console.log('載入圖片尺寸:', {
      naturalWidth: image.naturalWidth,
      naturalHeight: image.naturalHeight,
    });

    // 先將整張圖繪製到 canvas
    ctx.clearRect(0, 0, canvas.width, canvas.height);
    ctx.drawImage(image, 0, 0, canvas.width, canvas.height);

    // 計算扭曲需要的三角形
    const triangles = calculateGeometry(wo, image, {
      subdivHeightThreshold,
      subdivRatio,
      subs,
    });
    console.log('計算得到的三角形數量:', triangles.length);

    // 清空畫面，再以三角形方式做扭曲貼圖
    ctx.clearRect(0, 0, canvas.width, canvas.height);
    triangles.forEach((tri) => {
      drawTriangle(
        ctx,
        image,
        tri.p0.x,
        tri.p0.y,
        tri.p1.x,
        tri.p1.y,
        tri.p2.x,
        tri.p2.y,
        tri.t0.u,
        tri.t0.v,
        tri.t1.u,
        tri.t1.v,
        tri.t2.u,
        tri.t2.v,
      );
    });

    if (doSecondDrawing) {
      const canvasOut = document.createElement('canvas');
      const ctxOut = canvasOut.getContext('2d');
      canvasOut.width = wo.width;
      canvasOut.height = wo.height;

      // 將第一個 canvas 的結果貼到第二個 canvas 上，並做偏移
      const tempData = canvas.toDataURL();
      const tempImage = await loadImage(tempData);

      ctxOut.clearRect(0, 0, canvasOut.width, canvasOut.height);
      ctxOut.drawImage(
        tempImage,
        0,
        0,
        canvas.width,
        canvas.height,
        offsetX,
        offsetY,
        canvasOut.width,
        canvasOut.height,
      );

      // 以第二個 canvas 的圖作為最終結果
      return canvasOut.toDataURL();
    }

    // 回傳處理後的圖片
    return canvas.toDataURL();
  } catch (err) {
    console.error('Bilinear warp error:', err);
    throw err;
  }
}

/**
 * 簡化的幾何拆分：把四邊形拆成兩個三角形。
 */
function calculateGeometry(wo, image, opts = {}) {
  const {subdivHeightThreshold, subdivRatio, subs} = opts;

  console.log('開始計算幾何形狀，輸入參數:', {
    cornerPoints: {
      p1: [wo.x1, wo.y1],
      p2: [wo.x2, wo.y2],
      p3: [wo.x3, wo.y3],
      p4: [wo.x4, wo.y4],
    },
    imageSize: {
      width: image.naturalWidth,
      height: image.naturalHeight,
    },
  });

  // 定義 Point 和 TextCoord 類別
  class Point {
    constructor(x, y) {
      this.x = parseInt(x);
      this.y = parseInt(y);
    }
  }

  class TextCoord {
    constructor(u, v) {
      this.u = u;
      this.v = v;
    }
  }

  class Triangle {
    constructor(p0, p1, p2, t0, t1, t2) {
      this.p0 = p0;
      this.p1 = p1;
      this.p2 = p2;
      this.t0 = t0;
      this.t1 = t1;
      this.t2 = t2;
    }
  }

  const triangles = [];
  let divs = 1; // 水平細分，預設 1

  // 四個角點
  const p1 = new Point(wo.x1, wo.y1);
  const p2 = new Point(wo.x2, wo.y2);
  const p3 = new Point(wo.x3, wo.y3);
  const p4 = new Point(wo.x4, wo.y4);

  // 計算邊緣向量
  const dx1 = p4.x - p1.x;
  const dy1 = p4.y - p1.y;
  const dx2 = p3.x - p2.x;
  const dy2 = p3.y - p2.y;

  // 取得圖片尺寸
  const imgW = image.naturalWidth;
  const imgH = image.naturalHeight;

  const h = (wo.y3 + wo.y4 - wo.y1 - wo.y2) / 2;
  if (Math.abs(h) < subdivHeightThreshold) {
    divs = parseInt(Math.abs(h) / subdivRatio, 10) || 1; // 避免出現 0
  }
  console.log('計算高度:', h, '=> 使用 divs:', divs, ', subs:', subs);

  // 邊緣修正值 (根據需求可調整)
  const add_num = 0.6;

  // 生成細分三角形
  for (let sub = 0; sub < subs; ++sub) {
    const curRow = sub / subs;
    const nextRow = (sub + 1) / subs;

    // 計算當前行和下一行的頂點
    const curRowX1 = p1.x + dx1 * curRow;
    const curRowY1 = p1.y + dy1 * curRow;
    const curRowX2 = p2.x + dx2 * curRow;
    const curRowY2 = p2.y + dy2 * curRow;

    const nextRowX1 = p1.x + dx1 * nextRow;
    const nextRowY1 = p1.y + dy1 * nextRow;
    const nextRowX2 = p2.x + dx2 * nextRow;
    const nextRowY2 = p2.y + dy2 * nextRow;

    for (let div = 0; div < divs; ++div) {
      const curCol = div / divs;
      const nextCol = (div + 1) / divs;

      const dCurX = curRowX2 - curRowX1;
      const dCurY = curRowY2 - curRowY1;
      const dNextX = nextRowX2 - nextRowX1;
      const dNextY = nextRowY2 - nextRowY1;

      const p1x = curRowX1 + dCurX * curCol;
      const p1y = curRowY1 + dCurY * curCol;
      const p2x = curRowX1 + dCurX * nextCol;
      const p2y = curRowY1 + dCurY * nextCol;
      const p3x = nextRowX1 + dNextX * nextCol;
      const p3y = nextRowY1 + dNextY * nextCol;
      const p4x = nextRowX1 + dNextX * curCol;
      const p4y = nextRowY1 + dNextY * curCol;

      // 計算紋理座標
      const u1 = curCol * imgW;
      const u2 = nextCol * imgW;
      const v1 = curRow * imgH;
      const v2 = nextRow * imgH;

      // 根據方向來拼接三角形
      if (p3y > p1y) {
        triangles.push(
          new Triangle(
            new Point(p1x - add_num, p1y),
            new Point(p3x + add_num * 2, p3y + add_num),
            new Point(p4x - add_num, p4y + add_num),
            new TextCoord(u1, v1),
            new TextCoord(u2, v2),
            new TextCoord(u1, v2),
          ),
        );

        triangles.push(
          new Triangle(
            new Point(p1x - add_num * 2, p1y),
            new Point(p2x + add_num, p2y),
            new Point(p3x + add_num, p3y + add_num),
            new TextCoord(u1, v1),
            new TextCoord(u2, v1),
            new TextCoord(u2, v2),
          ),
        );
      } else {
        triangles.push(
          new Triangle(
            new Point(p1x - add_num, p1y),
            new Point(p3x, p3y - add_num),
            new Point(p4x + add_num, p4y + add_num),
            new TextCoord(u1, v1),
            new TextCoord(u2, v2),
            new TextCoord(u1, v2),
          ),
        );

        triangles.push(
          new Triangle(
            new Point(p1x - add_num, p1y + add_num),
            new Point(p2x - add_num, p2y - add_num),
            new Point(p3x, p3y),
            new TextCoord(u1, v1),
            new TextCoord(u2, v1),
            new TextCoord(u2, v2),
          ),
        );
      }
    }
  }

  return triangles;
}

/**
 * 在 canvas 上根據三角形座標貼圖
 */
function drawTriangle(
  ctx,
  im,
  x0,
  y0,
  x1,
  y1,
  x2,
  y2,
  sx0,
  sy0,
  sx1,
  sy1,
  sx2,
  sy2,
) {
  // 先畫三角形區域
  ctx.save();
  ctx.beginPath();
  ctx.moveTo(x0, y0);
  ctx.lineTo(x1, y1);
  ctx.lineTo(x2, y2);
  ctx.closePath();
  ctx.clip();

  // 計算變換矩陣的分母
  const denom = sx0 * (sy2 - sy1) - sx1 * sy2 + sx2 * sy1 + (sx1 - sx2) * sy0;
  if (denom === 0) {
    console.warn('變換矩陣分母為零，跳過此三角形');
    ctx.restore();
    return;
  }

  // 分別計算 m11, m12, m21, m22, dx, dy
  const m11 =
    -(sy0 * (x2 - x1) - sy1 * x2 + sy2 * x1 + (sy1 - sy2) * x0) / denom;
  const m12 =
    (sy1 * y2 + sy0 * (y1 - y2) - sy2 * y1 + (sy2 - sy1) * y0) / denom;
  const m21 =
    (sx0 * (x2 - x1) - sx1 * x2 + sx2 * x1 + (sx1 - sx2) * x0) / denom;
  const m22 =
    -(sx1 * y2 + sx0 * (y1 - y2) - sx2 * y1 + (sx2 - sx1) * y0) / denom;
  const dx =
    (sx0 * (sy2 * x1 - sy1 * x2) +
      sy0 * (sx1 * x2 - sx2 * x1) +
      (sx2 * sy1 - sx1 * sy2) * x0) /
      denom -
    1;
  const dy =
    (sx0 * (sy2 * y1 - sy1 * y2) +
      sy0 * (sx1 * y2 - sx2 * y1) +
      (sx2 * sy1 - sx1 * sy2) * y0) /
      denom -
    1;

  // 應用變換矩陣
  ctx.transform(m11, m12, m21, m22, dx, dy);

  // 繪製整張圖片
  ctx.drawImage(im, 0, 0);
  ctx.restore();
}
