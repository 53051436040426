import cv from '@techstark/opencv-js';
import {waitForOpenCV, createTempCanvas, releaseMats} from './openCVHelper';

/**
 * 分析圖片 DPI 和尺寸資訊
 * @param {HTMLImageElement} image - 圖片元素
 * @returns {Promise<Object>} 圖片資訊
 */
export const analyzeImage = async (image) => {
  await waitForOpenCV();

  if (!(image instanceof HTMLImageElement)) {
    throw new Error('image is not a valid HTMLImageElement');
  }

  const tempCanvas = createTempCanvas(image);
  const mat = cv.imread(tempCanvas);

  const physicalWidth = mat.cols;
  const physicalHeight = mat.rows;
  const baseDPI = 96;
  const devicePixelRatio = window.devicePixelRatio || 1;
  const estimatedDPI = Math.round(
    (physicalWidth / image.naturalWidth) * baseDPI * devicePixelRatio,
  );

  const imageQuality = {
    width: physicalWidth,
    height: physicalHeight,
    aspectRatio: physicalWidth / physicalHeight,
    estimatedDPI,
    channels: mat.channels(),
    depth: mat.depth(),
    dimensions: mat.size(),
    pixelCount: mat.size().height * mat.size().width,
  };

  releaseMats([mat]);

  return imageQuality;
};

/**
 * @param {HTMLImageElement} image - 圖片元素
 * @param {number} minDPI - 最低 DPI 要求
 * @returns {Promise<boolean>} 是否符合要求
 */
export const checkImageDPI = async (image, minDPI = 300) => {
  const imageInfo = await analyzeImage(image);
  return imageInfo.estimatedDPI >= minDPI;
};
