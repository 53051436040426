import React, {useState} from 'react';
import styled from 'styled-components';
import Item from '../../../Widgets/Item';
import {ReactComponent as FitHeightIcon} from '../../../Assets/icons/fit-height.svg';
import {ReactComponent as FitWidthIcon} from '../../../Assets/icons/fit-width.svg';
import {ReactComponent as ReflectHorizontalIcon} from '../../../Assets/icons/reflect-horizontal.svg';
import {ReactComponent as ReflectVerticalIcon} from '../../../Assets/icons/reflect-vertical.svg';
import {ReactComponent as HorizontalCenterIcon} from '../../../Assets/icons/horizontal_center.svg';
import {ReactComponent as VerticalCenterIcon} from '../../../Assets/icons/vertical_center.svg';
import {ReactComponent as RotateRightIcon} from '../../../Assets/icons/rotate-right.svg';
import {ReactComponent as RotateLeftIcon} from '../../../Assets/icons/rotate-left.svg';
import {useDesignsStore} from '../../../../../Stores';

const EDIT_ITEMS = [
  {key: 'fit-height', icon: <FitHeightIcon />, tooltip: '填滿高度'},
  {key: 'fit-width', icon: <FitWidthIcon />, tooltip: '填滿寬度'},
  {
    key: 'reflect-horizontal',
    icon: <ReflectHorizontalIcon />,
    tooltip: '水平翻轉',
  },
  {key: 'reflect-vertical', icon: <ReflectVerticalIcon />, tooltip: '垂直翻轉'},
  {key: 'rotate-left', icon: <RotateLeftIcon />, tooltip: '向左旋轉'},
  {key: 'rotate-right', icon: <RotateRightIcon />, tooltip: '向右旋轉'},
];

const ALIGN_ITEMS = [
  {key: 'horizontal', icon: <HorizontalCenterIcon />, tooltip: '水平對齊'},
  {key: 'vertical', icon: <VerticalCenterIcon />, tooltip: '垂直對齊'},
];

const EditContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: calc(100% - 55px);
  row-gap: 24px;
`;

const EditHeader = styled.h4`
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 0;
`;

const EditItemsContainer = styled.div`
  display: flex;
  align-items: center;
  column-gap: 13px;
`;

function Edit() {
  const {
    fitElementToStage,
    rotateElement,
    centerElement,
    reflectElement,
    selectedId,
  } = useDesignsStore();
  const [selectedItem, setSelectedItem] = useState(null);

  const handleItemClick = (key) => {
    if (!selectedId) return;

    const STAGE_WIDTH = 600; // 假設畫布寬度為 600
    const STAGE_HEIGHT = 600; // 假設畫布高度為 600

    switch (key) {
      case 'fit-height':
        fitElementToStage(selectedId, 'height', STAGE_WIDTH, STAGE_HEIGHT);
        break;
      case 'fit-width':
        fitElementToStage(selectedId, 'width', STAGE_WIDTH, STAGE_HEIGHT);
        break;
      case 'rotate-left':
        rotateElement(selectedId, 'left');
        break;
      case 'rotate-right':
        rotateElement(selectedId, 'right');
        break;
      case 'horizontal':
        centerElement(selectedId, 'horizontal', STAGE_WIDTH, STAGE_HEIGHT);
        break;
      case 'vertical':
        centerElement(selectedId, 'vertical', STAGE_WIDTH, STAGE_HEIGHT);
        break;
      case 'reflect-horizontal':
        reflectElement(selectedId, 'horizontal');
        break;
      case 'reflect-vertical':
        reflectElement(selectedId, 'vertical');
        break;
      default:
        break;
    }
  };

  return (
    <>
      <EditContainer>
        <EditHeader>編輯</EditHeader>
        <EditItemsContainer>
          {EDIT_ITEMS.map((item) => (
            <Item
              key={item.key}
              icon={item.icon}
              active={selectedItem === item.key}
              tooltip={item.tooltip}
              onClick={() => handleItemClick(item.key)}
            />
          ))}
        </EditItemsContainer>
        <EditHeader>對齊</EditHeader>
        <EditItemsContainer>
          {ALIGN_ITEMS.map((item) => (
            <Item
              key={item.key}
              icon={item.icon}
              active={selectedItem === item.key}
              tooltip={item.tooltip}
              onClick={() => handleItemClick(item.key)}
            />
          ))}
        </EditItemsContainer>
      </EditContainer>
    </>
  );
}

export default Edit;
