export const parseSvgPath = (svgText) => {
  const parser = new DOMParser();
  const svgDoc = parser.parseFromString(svgText, 'image/svg+xml');

  // 尋找 path 元素
  const pathElement = svgDoc.querySelector('path');
  if (pathElement) {
    return pathElement.getAttribute('d');
  }

  // 尋找 rect 元素
  const rectElement = svgDoc.querySelector('rect');
  if (rectElement) {
    const x = parseFloat(rectElement.getAttribute('x') || 0);
    const y = parseFloat(rectElement.getAttribute('y') || 0);
    const width = parseFloat(rectElement.getAttribute('width'));
    const height = parseFloat(rectElement.getAttribute('height'));
    const rx = parseFloat(rectElement.getAttribute('rx') || 0);
    const ry = parseFloat(rectElement.getAttribute('ry') || 0);

    return {
      type: 'rect',
      x,
      y,
      width,
      height,
      rx,
      ry,
    };
  }

  // 尋找 circle 元素
  const circleElement = svgDoc.querySelector('circle');
  if (circleElement) {
    const cx = parseFloat(circleElement.getAttribute('cx') || 0);
    const cy = parseFloat(circleElement.getAttribute('cy') || 0);
    const r = parseFloat(circleElement.getAttribute('r') || 0);

    // 將圓形轉換為 SVG path 指令
    return `M ${cx - r} ${cy} 
            A ${r} ${r} 0 0 1 ${cx + r} ${cy}
            A ${r} ${r} 0 0 1 ${cx - r} ${cy}`;
  }

  return null;
};
