import React from 'react';
import styled from 'styled-components';
import {Tooltip} from 'antd';

const appConfig = require('../../../data.json');

const ItemContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: ${(props) => (props.hasText ? '80px' : '48px')};
  height: ${(props) => (props.hasText ? '76' : '48px')};
  background-color: ${(props) =>
    props.active ? appConfig.designsTheme.primaryBG : '#F8F8F8'};
  border-radius: ${(props) => (props.hasText ? '12px' : '8px')};
  cursor: pointer;
  transition: background-color 0.3s;
  padding: ${(props) => (props.hasText ? '8px 16px' : '12px')};

  &:hover {
    background-color: ${(props) =>
      props.active ? appConfig.designsTheme.primaryBG : '#F8F8F8'};
  }
`;

const IconWrapper = styled.div`
  color: ${(props) => (props.active ? appConfig.designsTheme.primary : '#000')};
  margin-bottom: 0;
  line-height: 1;
`;

const Text = styled.span`
  font-size: 16px;
  color: ${(props) =>
    props.active ? appConfig.designsTheme.primary : 'inherit'};
  font-weight: ${(props) => (props.active ? '500' : '400')};
`;

function Item({icon, text, active = false, onClick, tooltip}) {
  const hasText = !!text;

  return (
    <Tooltip title={tooltip}>
      <ItemContainer active={active} hasText={hasText} onClick={onClick}>
        {icon && (
          <IconWrapper active={active} hasText={hasText}>
            {icon}
          </IconWrapper>
        )}
        {text && <Text active={active}>{text}</Text>}
      </ItemContainer>
    </Tooltip>
  );
}

export default Item;
