import React, {useState} from 'react';
import styled, {css} from 'styled-components';
import PanelHeader from '../../Widgets/PanelHeader';
import ColorPicker from '../ColorPicker';
import MockBackgroundImage from '../../Assets/images/bg.png';
import ActionButton from '../../Widgets/ActionButton';
import {ReactComponent as DeleteIcon} from '../../Assets/icons/delete.svg';
import {useDesignsStore} from '../../../../Stores';

const BACKGROUND_GROUPS = [
  {
    key: 'group1',
    label: '群組',
    itemsNum: 4,
  },
  {
    key: 'group2',
    label: '群組',
    itemsNum: 7,
  },
  {
    key: 'group3',
    label: '群組',
    itemsNum: 8,
  },
  {
    key: 'group4',
    label: '群組',
    itemsNum: 10,
  },
];

const BackgroundContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: calc(100% - 55px);
  row-gap: 24px;
`;

const BackgroundTabs = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  column-gap: 24px;
  width: 100%;
`;

const BackgroundTabsItem = styled.div`
  font-size: 16px;
  font-weight: 500;
  color: #000;
  cursor: pointer;
  padding-bottom: 8px;
  border-bottom: 1px solid transparent;
  transition: all 0.3s;
  ${({active}) =>
    active &&
    css`
      border-bottom: 1px solid #000;
    `}
`;

const BackgroundGroup = styled.div`
  display: flex;
  flex-direction: column;
`;

const BackgroundGroupHeader = styled.h4`
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 10px;
`;

const BackgroundGroupItems = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px;
`;

const BackgroundItem = styled.div`
  width: 80px;
  height: 80px;
  border-radius: 12px;
  cursor: pointer;
  overflow: hidden;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

const BackgroundContent = styled.div`
  overflow-y: auto;
  flex: 1;
  display: flex;
  flex-direction: column;
  row-gap: 24px;
`;

function Background() {
  const [currentTab, setCurrentTab] = useState('all');
  const [selectedColor, setSelectedColor] = useState('#BF332B');
  const {setSelectedToolbarItem} = useDesignsStore();

  return (
    <>
      <PanelHeader onClick={() => setSelectedToolbarItem('default')}>
        新增背景
      </PanelHeader>
      <BackgroundContainer>
        <BackgroundTabs>
          <BackgroundTabsItem
            active={currentTab === 'all'}
            onClick={() => setCurrentTab('all')}>
            全部背景
          </BackgroundTabsItem>
          <BackgroundTabsItem
            active={currentTab === 'recent'}
            onClick={() => setCurrentTab('recent')}>
            最近使用
          </BackgroundTabsItem>
        </BackgroundTabs>
        <BackgroundContent>
          <ColorPicker
            selectedColor={selectedColor}
            onClick={setSelectedColor}
          />
          {BACKGROUND_GROUPS.map((group, idx) => (
            <BackgroundGroup key={group.key}>
              <BackgroundGroupHeader>
                {group.label + ' ' + (idx + 1)}
              </BackgroundGroupHeader>
              <BackgroundGroupItems>
                {Array.from({length: group.itemsNum}).map((_, idx) => (
                  <BackgroundItem key={idx}>
                    <img src={MockBackgroundImage} alt="background" />
                  </BackgroundItem>
                ))}
              </BackgroundGroupItems>
            </BackgroundGroup>
          ))}
        </BackgroundContent>
        <ActionButton>
          <DeleteIcon />
          刪除背景
        </ActionButton>
      </BackgroundContainer>
    </>
  );
}

export default Background;
