import React, {useState} from 'react';
import styled from 'styled-components';
import PanelHeader from '../../Widgets/PanelHeader';
import {useDesignsStore} from '../../../../Stores';
import ActionButton from '../../Widgets/ActionButton';
import {UploadOutlined, InfoCircleOutlined} from '@ant-design/icons';

const appConfig = require('../../../../data.json');

const WhiteInkContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: calc(100% - 55px);
  row-gap: 24px;
`;

const WhiteInkHeader = styled.h4`
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 0;
`;

const WhiteInkOptions = styled.div`
  display: flex;
  align-items: center;
  column-gap: 12px;
`;

const WhiteInkOption = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  font-weight: 500;
  border: 1px solid
    ${(props) => (props.active ? appConfig.designsTheme.primary : '#6B7280')};
  border-radius: 12px;
  color: ${(props) =>
    props.active ? appConfig.designsTheme.primary : '#6B7280'};
  width: 80px;
  height: 44px;
  cursor: pointer;
  transition: all 0.3s ease;
`;

const WhiteInkTips = styled.div`
  font-size: 14px;
  color: #5b616e;
  margin-top: auto;
  margin-bottom: -8px;
  text-align: center;
`;

function WhiteInk() {
  const {isWhiteInkActive, setIsWhiteInkActive} = useDesignsStore();

  return (
    <>
      <PanelHeader showArrow={false}>
        編輯白墨
        <InfoCircleOutlined style={{fontSize: '20px', marginLeft: '4px'}} />
      </PanelHeader>
      <WhiteInkContainer>
        <WhiteInkHeader>白墨印刷</WhiteInkHeader>
        <WhiteInkOptions>
          <WhiteInkOption
            active={isWhiteInkActive}
            onClick={() => setIsWhiteInkActive(true)}>
            有白墨
          </WhiteInkOption>
          <WhiteInkOption
            active={!isWhiteInkActive}
            onClick={() => setIsWhiteInkActive(false)}>
            無白墨
          </WhiteInkOption>
        </WhiteInkOptions>
        <WhiteInkTips>注意：白墨圖僅支持png黑稿</WhiteInkTips>
      </WhiteInkContainer>
    </>
  );
}

export default WhiteInk;
