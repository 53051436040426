import React, {useState} from 'react';
import styled from 'styled-components';
import Item from '../../../Widgets/Item';
import {ReactComponent as AlignCenterIcon} from '../../../Assets/icons/align-center.svg';
import {ReactComponent as AlignLeftIcon} from '../../../Assets/icons/align-left.svg';
import {ReactComponent as AlignRightIcon} from '../../../Assets/icons/align-right.svg';

const ALIGNMENT_ITEMS = [
  {
    key: 'left',
    icon: <AlignLeftIcon />,
  },
  {
    key: 'center',
    icon: <AlignCenterIcon />,
  },
  {
    key: 'right',
    icon: <AlignRightIcon />,
  },
];

const StyledAlignmentContainer = styled.div`
  display: flex;
  align-items: center;
  column-gap: 24px;
  margin-bottom: 24px;
`;

const StyledAlignmentTitle = styled.h4`
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 16px;
`;

function Alignment() {
  const [selectedAlign, setSelectedAlign] = useState('left');

  return (
    <>
      <StyledAlignmentTitle>對齊方式</StyledAlignmentTitle>
      <StyledAlignmentContainer>
        {ALIGNMENT_ITEMS.map((item) => (
          <Item
            key={item.key}
            icon={item.icon}
            active={selectedAlign === item.key}
            onClick={() => setSelectedAlign(item.key)}
          />
        ))}
      </StyledAlignmentContainer>
    </>
  );
}

export default Alignment;
