import cv from '@techstark/opencv-js';
import {waitForOpenCV} from './openCVHelper';

// 常數配置
const CONFIG = {
  SHAPE: {
    VERTICES: {
      RECTANGLE: 4,
      MIN_CIRCLE: 6,
    },
    CIRCULARITY: {
      THRESHOLD: 0.8,
    },
  },
  CONTOUR: {
    EPSILON_RATIO: 0.02,
    SCALE: 1,
  },
};

/**
 * 提取並平滑圖片的透明區域輪廓點
 * @param {HTMLImageElement | HTMLCanvasElement} imageElement - 帶透明通道的圖片或 Canvas 元素
 * @returns {Promise<Array<Array<{x: number, y: number}>>>} - 輪廓座標陣列
 */
export const getSmoothContours = async (imageElement) => {
  await waitForOpenCV();
  return new Promise((resolve, reject) => {
    try {
      // 初始化 OpenCV
      const src = cv.imread(imageElement); // 讀取圖片
      const rgbaPlanes = new cv.MatVector();
      const contours = new cv.MatVector();
      const hierarchy = new cv.Mat();
      let alpha = new cv.Mat();
      const binaryMask = new cv.Mat();
      const blurredMask = new cv.Mat();

      // 分離 Alpha 通道
      cv.split(src, rgbaPlanes);
      alpha = rgbaPlanes.get(3);

      // 建立透明區域遮罩
      cv.threshold(alpha, binaryMask, 150, 255, cv.THRESH_BINARY_INV);

      // 平滑遮罩邊緣
      cv.GaussianBlur(binaryMask, blurredMask, new cv.Size(5, 5), 0);

      // 找平滑後的輪廓
      cv.findContours(
        blurredMask,
        contours,
        hierarchy,
        cv.RETR_EXTERNAL,
        cv.CHAIN_APPROX_SIMPLE,
      );

      // 提取輪廓點座標
      const contourPoints = [];
      for (let i = 0; i < contours.size(); i++) {
        const points = [];
        const contour = contours.get(i);
        for (let j = 0; j < contour.data32S.length; j += 2) {
          points.push({
            x: contour.data32S[j],
            y: contour.data32S[j + 1],
          });
        }
        contourPoints.push(points);
      }

      // 釋放記憶體
      src.delete();
      rgbaPlanes.delete();
      alpha.delete();
      binaryMask.delete();
      blurredMask.delete();
      contours.delete();
      hierarchy.delete();

      resolve(contourPoints); // 返回輪廓座標
    } catch (error) {
      reject(error);
    }
  });
};

/**
 * 檢測透明區域並計算四個角點
 * @param {HTMLImageElement | HTMLCanvasElement} imageElement - 帶透明通道的圖片或 Canvas 元素
 * @returns {Promise<{corners: Array<{x: number, y: number}>, mask: cv.Mat}>}
 */
export const detectTransparentRegionCorners = async (
  imageElement,
  scale = CONFIG.CONTOUR.SCALE,
) => {
  await waitForOpenCV();
  return new Promise((resolve, reject) => {
    try {
      const src = cv.imread(imageElement);
      const rgbaPlanes = new cv.MatVector();
      const contours = new cv.MatVector();
      const hierarchy = new cv.Mat();

      // 分離 Alpha 通道並建立遮罩
      cv.split(src, rgbaPlanes);
      const alpha = rgbaPlanes.get(3);
      const mask = new cv.Mat();
      cv.threshold(alpha, mask, 150, 255, cv.THRESH_BINARY);
      cv.bitwise_not(mask, mask);

      // 尋找輪廓
      cv.findContours(
        mask,
        contours,
        hierarchy,
        cv.RETR_EXTERNAL,
        cv.CHAIN_APPROX_SIMPLE,
      );

      // 找到最大輪廓
      const largestContour = findLargestContour(contours);

      // 計算近似多邊形
      const epsilon =
        CONFIG.CONTOUR.EPSILON_RATIO * cv.arcLength(largestContour, true);
      const approx = new cv.Mat();
      cv.approxPolyDP(largestContour, approx, epsilon, true);

      // 判斷形狀類型
      const shapeType = determineShapeType(approx, largestContour);

      // 計算旋轉矩形和角點
      const rect = cv.minAreaRect(largestContour);
      const points = calculateRotatedPoints(rect);
      const scaledCorners = calculateScaledCorners(points, scale);

      resolve({
        corners: scaledCorners,
        shapeType,
      });

      // 釋放記憶體
      cleanupResources(src, rgbaPlanes, contours, hierarchy, approx, mask);
    } catch (error) {
      reject(error);
    }
  });
};

// 輔助函數：找到最大輪廓
const findLargestContour = (contours) => {
  let maxArea = 0;
  let maxContourIndex = 0;

  for (let i = 0; i < contours.size(); i++) {
    const area = cv.contourArea(contours.get(i));
    if (area > maxArea) {
      maxArea = area;
      maxContourIndex = i;
    }
  }

  return contours.get(maxContourIndex);
};

// 輔助函數：清理資源
const cleanupResources = (...resources) => {
  resources.forEach((resource) => resource?.delete());
};

// 形狀類型判斷
const determineShapeType = (approx, largestContour) => {
  const vertices = approx.rows;

  if (vertices === CONFIG.SHAPE.VERTICES.RECTANGLE) {
    const arcLength = cv.arcLength(largestContour, true);
    const area = cv.contourArea(largestContour);
    const circularity = (4 * Math.PI * area) / (arcLength * arcLength);

    return circularity > CONFIG.SHAPE.CIRCULARITY.THRESHOLD
      ? 'roundedRectangle'
      : 'rectangle';
  }

  return vertices > CONFIG.SHAPE.VERTICES.MIN_CIRCLE ? 'circle' : 'rectangle';
};

// 計算旋轉後的頂點
const calculateRotatedPoints = (rect) => {
  const {center, size, angle} = rect;
  const angleInRadians = (angle * Math.PI) / 180;
  const cos = Math.cos(angleInRadians);
  const sin = Math.sin(angleInRadians);
  const width = size.width / 2;
  const height = size.height / 2;

  return [
    // 左上
    {
      x: Math.round(center.x - width * cos + height * sin),
      y: Math.round(center.y - width * sin - height * cos),
    },
    // 右上
    {
      x: Math.round(center.x + width * cos + height * sin),
      y: Math.round(center.y + width * sin - height * cos),
    },
    // 左下
    {
      x: Math.round(center.x - width * cos - height * sin),
      y: Math.round(center.y - width * sin + height * cos),
    },
    // 右下
    {
      x: Math.round(center.x + width * cos - height * sin),
      y: Math.round(center.y + width * sin + height * cos),
    },
  ];
};

// 計算縮放後的角點
const calculateScaledCorners = (points, scale) => {
  const centerX = points.reduce((sum, point) => sum + point.x, 0) / 4;
  const centerY = points.reduce((sum, point) => sum + point.y, 0) / 4;

  return points.map((point) => ({
    x: Math.round(centerX + (point.x - centerX) * scale),
    y: Math.round(centerY + (point.y - centerY) * scale),
  }));
};

export default getSmoothContours;
