import React from 'react';
import styled from 'styled-components';
import {ReactComponent as SwitchIcon} from '../Assets/icons/switch.svg';
import {ReactComponent as CopyIcon} from '../Assets/icons/copy.svg';
import {ReactComponent as DeleteIcon} from '../Assets/icons/delete.svg';
import ImageUploader from '../Components/ImageUploader';
import {useDesignsStore} from '../../../Stores';
import {Tooltip} from 'antd';

const ACTIONS_BAR_ITEMS = [
  {
    key: 'switch',
    icon: <SwitchIcon />,
    tooltip: '切換圖片',
    wrapper: ImageUploader,
  },
  {
    key: 'copy',
    icon: <CopyIcon />,
    tooltip: '複製圖片',
  },
  {
    key: 'delete',
    icon: <DeleteIcon />,
    tooltip: '刪除圖片',
  },
];

const ActionsBarContainer = styled.div`
  position: absolute;
  top: 68px;
  left: 50%;
  transform: translateX(-50%);
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 6px;
  box-shadow: 1px 2px 5px 0px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  column-gap: 8px;
  width: 124px;
  height: 44px;
  background-color: white;
  z-index: 100;

  @media (max-width: 1441px) {
    top: 48px;
  }
`;

const ActionButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  padding: 4px;
  cursor: pointer;
`;

function ActionsBar() {
  const {
    selectedId,
    removeUploadedImage,
    copyElement,
    setSelectedId,
    updateCanvasElement,
  } = useDesignsStore();

  const handleItemClick = (key) => {
    if (!selectedId) return;

    switch (key) {
      case 'switch':
        // switch 事件由 Uploader 處理
        break;
      case 'copy':
        copyElement(selectedId);
        break;
      case 'delete':
        removeUploadedImage(selectedId);
        setSelectedId(null);
        break;
      default:
        break;
    }
  };

  const handleImageUploaded = ({url, isLowResolution = false}) => {
    if (selectedId) {
      updateCanvasElement(selectedId, {src: url, isLowResolution});
    }
  };

  if (!selectedId) return null;

  return (
    <ActionsBarContainer>
      {ACTIONS_BAR_ITEMS.map((item) => {
        const ButtonContent = (
          <Tooltip title={item.tooltip}>
            <ActionButton
              key={item.key}
              onClick={() => handleItemClick(item.key)}>
              {item.icon}
            </ActionButton>
          </Tooltip>
        );

        return item.wrapper ? (
          <item.wrapper key={item.key} onImageUploaded={handleImageUploaded}>
            {ButtonContent}
          </item.wrapper>
        ) : (
          ButtonContent
        );
      })}
    </ActionsBarContainer>
  );
}

export default ActionsBar;
