export const createAuthSlice = (set, get) => ({
  token: null,
  isTokenLoading: false,
  setToken: (token) => set({token}),
  getJwtToken: async (app) => {
    try {
      set({isTokenLoading: true});
      const {token} = await app.actions.getJwtToken();
      set({token});
      return token;
    } catch (error) {
      console.error('取得 JWT Token 失敗:', error);
      throw error;
    } finally {
      set({isTokenLoading: false});
    }
  },
  clearToken: () => set({token: null}),
});
