import React, {useState, useRef, useEffect, useContext} from 'react';
import styled from 'styled-components';
import {Upload, message, Modal} from 'antd';
import {Close} from '@styled-icons/material';
import {Context} from '../../../AppContext';
import {ErrUploadFile} from '../../../errors';
import {useDesignsStore} from '../../../Stores';
import {UploadOutlined} from '@ant-design/icons';
import UploadProgress, {metadata} from '../../../Modals/UploadProgress';
import {analyzeImage, checkImageDPI} from '../Utils/imageAnalyze';
const appConfig = require('../../../data.json');

const STEPS = {
  CHECK_IMAGE: 1,
  UPLOAD: 2,
  UPDATE_ITEM: 3,
};

const INIT_STATE = {
  step: STEPS.CHECK_IMAGE,
  percentage: 0,
};

const MAX_FILE_SIZE_MB = 20;
const ALLOWED_FILE_TYPES = ['image/jpeg', 'image/png'];

const isFileTypeAllowed = (file) => {
  return ALLOWED_FILE_TYPES.includes(file.type);
};

const isFileSizeAllowed = (file) => {
  return file.size / 1024 / 1024 <= MAX_FILE_SIZE_MB;
};

const UploadWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

export default function ImageUploader({children, onImageUploaded}) {
  const [step, setStep] = useState(INIT_STATE.step);
  const [percentage, setPercentage] = useState(INIT_STATE.percentage);
  const [openDialog, setOpenDialog] = useState(false);
  const url = useRef(INIT_STATE.url);
  const app = useContext(Context);
  const {
    setUploadedImages,
    addCanvasElement,
    svgDimensions,
    setResolutionWarningModal,
    setUploadDialogOpen,
    showResolutionWarningModal,
    uploadDialogOpen,
  } = useDesignsStore();
  const [currentFile, setCurrentFile] = useState(null);

  const reset = () => {
    setStep(INIT_STATE.step);
    setPercentage(INIT_STATE.percentage);
    url.current = INIT_STATE.url;
    setOpenDialog(false);
  };

  useEffect(() => {
    const continueUpload = async () => {
      if (!currentFile) return;

      setOpenDialog(true);
      setStep(STEPS.UPLOAD);
      const result = await app.actions.uploadUserImage(currentFile);

      setStep(STEPS.UPDATE_ITEM);
      const imageId = Date.now().toString();
      await setUploadedImages({
        id: imageId,
        url: result.url,
        name: currentFile.name,
        originalSize: {
          width: currentFile.width,
          height: currentFile.height,
        },
        isLowResolution: true,
        lowResolutionType: 'upload',
      });

      if (onImageUploaded) {
        onImageUploaded({
          url: result.url,
          isLowResolution: true,
          lowResolutionType: 'upload',
        });
      } else {
        addCanvasElement({
          id: imageId,
          type: 'image',
          src: result.url,
          name: currentFile.name,
          originalSize: {
            width: currentFile.width,
            height: currentFile.height,
          },
          isLowResolution: true,
          lowResolutionType: 'upload',
        });
      }

      message.success('上傳成功');
      setOpenDialog(false);
      setCurrentFile(null);
    };

    if (!showResolutionWarningModal && currentFile) {
      if (uploadDialogOpen) {
        setCurrentFile(null);
        setOpenDialog(false);
        return;
      }
      continueUpload();
    }
  }, [showResolutionWarningModal, uploadDialogOpen]);

  useEffect(() => {
    if (uploadDialogOpen) {
      const uploadButton = document.querySelector(
        '.ant-upload input[type="file"]',
      );
      if (uploadButton) {
        uploadButton.click();
        setUploadDialogOpen(false);
      }
    }
  }, [uploadDialogOpen]);

  const upload = async (info) => {
    try {
      let file = info.file;
      setCurrentFile(file);
      setOpenDialog(true);

      setStep(STEPS.CHECK_IMAGE);
      const tempImage = new Image();
      tempImage.src = URL.createObjectURL(file);
      await new Promise((resolve) => {
        tempImage.onload = resolve;
      });

      const originalSize = {
        width: tempImage.naturalWidth,
        height: tempImage.naturalHeight,
      };

      URL.revokeObjectURL(tempImage.src);

      const isLowResolution =
        originalSize.width < svgDimensions.width ||
        originalSize.height < svgDimensions.height;

      if (isLowResolution) {
        setOpenDialog(false);
        setResolutionWarningModal('upload');
        return;
      }

      setStep(STEPS.UPLOAD);
      const result = await app.actions.uploadUserImage(file);

      setStep(STEPS.UPDATE_ITEM);
      const imageId = Date.now().toString();
      await setUploadedImages({
        id: imageId,
        url: result.url,
        name: file.name,
        originalSize,
        isLowResolution,
        lowResolutionType: isLowResolution ? 'upload' : null,
      });

      if (onImageUploaded) {
        onImageUploaded({
          url: result.url,
          isLowResolution,
          lowResolutionType: isLowResolution ? 'upload' : null,
        });
      } else {
        addCanvasElement({
          id: imageId,
          type: 'image',
          src: result.url,
          name: file.name,
          originalSize,
          isLowResolution,
          lowResolutionType: isLowResolution ? 'upload' : null,
        });
      }

      message.success('上傳成功');
      setOpenDialog(false);
    } catch (err) {
      console.warn(err);
      message.error(err.message || '上傳失敗');
      setOpenDialog(false);
      setStep(INIT_STATE.step);
      setPercentage(INIT_STATE.percentage);
    }
  };

  return (
    <>
      <Upload
        fileList={[]}
        name="file"
        beforeUpload={(file) => false}
        onChange={upload}
        accept="image/jpeg, image/png, image/webp">
        <UploadWrapper>
          {children || (
            <UploadOutlined
              style={{
                fontSize: '24px',
                color: appConfig.designsTheme.primary,
              }}
            />
          )}
        </UploadWrapper>
      </Upload>

      <Modal
        visible={openDialog}
        onCancel={reset}
        title={metadata.title}
        footer={false}
        closeIcon={
          <Close
            style={{position: 'absolute', right: 20, top: 20}}
            size={20}
            color="#000"
          />
        }
        width={metadata.width}>
        <UploadProgress percentage={percentage} step={step} />
      </Modal>
    </>
  );
}
