import React, {useState} from 'react';
import styled from 'styled-components';
import PanelHeader from '../../Widgets/PanelHeader';
import {InfoCircleOutlined} from '@ant-design/icons';
import Item from '../../Widgets/Item';
import {ReactComponent as ArroundIcon} from '../../Assets/icons/arround.svg';
import {ReactComponent as SquareIcon} from '../../Assets/icons/square.svg';
import {ReactComponent as CircleIcon} from '../../Assets/icons/circle.svg';
import {ReactComponent as TriangleIcon} from '../../Assets/icons/triangle.svg';
import {ReactComponent as RectangleIcon} from '../../Assets/icons/rectangle.svg';
import {ReactComponent as HeartIcon} from '../../Assets/icons/heart.svg';
import {useDesignsStore} from '../../../../Stores';

const appConfig = require('../../../../data.json');

const CUTTING_MOLD_ITEMS = [
  {key: 'arround', icon: <ArroundIcon />},
  {key: 'circle', icon: <CircleIcon />},
  {key: 'square', icon: <SquareIcon />},
  {key: 'heart', icon: <HeartIcon />},
  {key: 'rectangle', icon: <RectangleIcon />},
  {key: 'triangle', icon: <TriangleIcon />},
];

const SIZES = ['滿切', '1mm', '2mm', '3mm', '4mm', '5mm'];

const CuttingMoldContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: calc(100% - 55px);
  row-gap: 24px;
`;

const CuttingMoldHeader = styled.h4`
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 0;
`;

const CuttingMoldItemsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const SizeSelector = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  &::before {
    content: '';
    position: absolute;
    top: 5px;
    left: 10px;
    right: 14px;
    height: 2px;
    background-color: #f1f1f1;
    z-index: 0;
  }
`;

const SizeOption = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  z-index: 1;
`;

const SizeDot = styled.div`
  width: ${(props) => (props.selected ? '12px' : '8px')};
  height: ${(props) => (props.selected ? '12px' : '8px')};
  border-radius: 50%;
  background-color: ${(props) =>
    props.selected ? appConfig.designsTheme.primary : '#F1F1F1'};
  border: ${(props) =>
    props.selected ? `2px solid ${appConfig.designsTheme.primaryBG}` : 'none'};
  margin-bottom: 4px;
  transition: background-color 0.3s ease;
`;

const SizeLabel = styled.span`
  font-size: 14px;
  color: ${(props) =>
    props.selected ? appConfig.designsTheme.primary : '#8E95A2'};
  transition: all 0.3s ease;
`;

function CuttingMold() {
  const {setSelectedToolbarItem} = useDesignsStore();
  const [selectedItem, setSelectedItem] = useState(null);
  const [selectedSize, setSelectedSize] = useState('2mm');

  return (
    <>
      <PanelHeader onClick={() => setSelectedToolbarItem('default')}>
        編輯刀模
        <InfoCircleOutlined style={{fontSize: '20px', marginLeft: '4px'}} />
      </PanelHeader>
      <CuttingMoldContainer>
        <CuttingMoldHeader>編輯</CuttingMoldHeader>
        <CuttingMoldItemsContainer>
          {CUTTING_MOLD_ITEMS.map((item) => (
            <Item
              key={item.key}
              icon={item.icon}
              active={selectedItem === item.key}
              onClick={() => setSelectedItem(item.key)}
            />
          ))}
        </CuttingMoldItemsContainer>
        <CuttingMoldHeader>描邊尺寸</CuttingMoldHeader>
        <SizeSelector>
          {SIZES.map((size) => (
            <SizeOption key={size} onClick={() => setSelectedSize(size)}>
              <SizeDot selected={selectedSize === size} />
              <SizeLabel selected={selectedSize === size}>{size}</SizeLabel>
            </SizeOption>
          ))}
        </SizeSelector>
      </CuttingMoldContainer>
    </>
  );
}

export default CuttingMold;
