export const createPreviewMiddleware = (config) => (set, get, api) => {
  const wrappedSet = async (args) => {
    await set(args);

    if (typeof args === 'function') {
      // 因為目前版本的 zustand 還不支援非同步，所以需要使用 setTimeout 來確保 state 更新後再執行，待之後更新版本後可替換
      setTimeout(() => {
        get().generatePreviewImage();
      }, 300);
    }
  };

  return config(wrappedSet, get, api);
};
