import React from 'react';
import styled from 'styled-components';
import {Modal} from 'antd';
import {useDesignsStore} from '../../../Stores';
import {InfoCircleOutlined} from '@ant-design/icons';

const StyledModal = styled(Modal)`
  .ant-modal-close {
    display: none;
  }
  .ant-modal-content {
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    max-height: 800px;
  }
  .ant-modal-header {
    padding: 24px 24px 16px;
    background-color: #fff;
    border-radius: 16px 16px 0 0;
    border-bottom: none;
    .ant-modal-title {
      font-size: 24px;
      font-weight: 500;
      color: #000;
      line-height: 1.58;
      letter-spacing: 2px;
    }
  }
  .ant-modal-body {
    padding: 0px 24px;
    flex: 1;
    overflow-y: auto;
    p {
      font-size: 16px;
      span {
        display: inline-block;
        color: #1fa689;
        padding: 0 4px;
      }
    }
  }
  .ant-modal-footer {
    padding: 24px;
    border-top: none;
    .ant-btn {
      display: inline-lex;
      align-items: center;
      justify-content: center;
      width: 114px;
      height: 56px;
      border-radius: 12px;
      font-size: 16px;
      padding: 0;
    }
  }
`;

const Notice = styled.div`
  padding: 12px;
  border-radius: 12px;
  background-color: #fff8eb;
  color: #d78100;
  font-size: 16px;
  margin-bottom: 16px;
`;

export default function ResolutionWarning() {
  const {
    showResolutionWarningModal,
    resolutionWarningType,
    closeResolutionWarningModal,
    svgDimensions,
    setUploadDialogOpen,
    clearResolutionWarningType,
  } = useDesignsStore();

  const handleWarningCancel = () => {
    if (resolutionWarningType === 'upload') {
      closeResolutionWarningModal();
    } else {
      clearResolutionWarningType();
      closeResolutionWarningModal();
    }
  };

  const handleWarningConfirm = () => {
    closeResolutionWarningModal();
    if (resolutionWarningType === 'upload') {
      clearResolutionWarningType();
      setUploadDialogOpen(true);
    }
  };

  return (
    <StyledModal
      visible={showResolutionWarningModal}
      onCancel={handleWarningCancel}
      title="圖片不符合設計規範"
      footer={[
        <button key="cancel" className="ant-btn" onClick={handleWarningCancel}>
          {resolutionWarningType === 'upload' ? '確定使用' : '繼續編輯'}
        </button>,
        <button
          key="confirm"
          className="ant-btn ant-btn-primary"
          onClick={handleWarningConfirm}>
          {resolutionWarningType === 'upload' ? '重新上傳' : '復原尺寸'}
        </button>,
      ]}
      width={640}
      centered>
      <Notice>
        <InfoCircleOutlined style={{color: '#d78100', marginRight: 8}} />
        注意：因圖片解析度不足所產生的印刷問題，將不納入售後服務範圍。
      </Notice>
      <p>
        {resolutionWarningType === 'upload'
          ? '經檢測您的圖片解析度低於傳圖規範尺寸，會導致印刷非常模糊，建議按照圖片設計規範尺寸'
          : '經檢測您的圖片放大後解析度低於設計規範，會導致印刷非常模糊，建議按照圖片設計規範尺寸'}
        <span>
          {svgDimensions?.width || 0} x {svgDimensions?.height || 0} 像素
        </span>{' '}
        {resolutionWarningType === 'upload' ? '重新傳圖。' : '縮小圖片尺寸。'}。
      </p>
    </StyledModal>
  );
}
