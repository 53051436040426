const EDIT_STAGE = {
  IMAGE: 'image',
  DIE_CUTTING: 'dieCutting',
  WHITE_INK: 'whiteInk',
  MODAL_CAPTURING: 'modalCapturing',
};

export const createStageSlice = (set, get) => ({
  currentEditStage: EDIT_STAGE.IMAGE,
  hasMask: true,
  isSaveModalOpen: false,
  isWhiteInkActive: true,

  setCurrentEditStage: (stage) => set({currentEditStage: stage}),
  setHasMask: (hasMask) => set({hasMask}),
  setIsSaveModalOpen: (isOpen) => set({isSaveModalOpen: isOpen}),
  setIsWhiteInkActive: (value) => set({isWhiteInkActive: value}),
  handleNextStage: () =>
    set(() => {
      const currentEditStage = get().currentEditStage;
      const hasMask = get().hasMask;
      const productSpec = get().productSpec;
      const hasWhiteInk = productSpec?.is_white_ink ?? true;

      let nextStage = currentEditStage;
      let brightnessFilter = get().brightnessFilter;
      let isSaveModalOpen = false;

      if (currentEditStage === EDIT_STAGE.IMAGE) {
        nextStage = hasMask
          ? hasWhiteInk
            ? EDIT_STAGE.WHITE_INK
            : EDIT_STAGE.MODAL_CAPTURING
          : EDIT_STAGE.DIE_CUTTING;
        brightnessFilter = nextStage === EDIT_STAGE.WHITE_INK;
      } else if (currentEditStage === EDIT_STAGE.DIE_CUTTING) {
        nextStage = hasWhiteInk
          ? EDIT_STAGE.WHITE_INK
          : EDIT_STAGE.MODAL_CAPTURING;
        brightnessFilter = nextStage === EDIT_STAGE.WHITE_INK;
      } else if (currentEditStage === EDIT_STAGE.WHITE_INK) {
        nextStage = EDIT_STAGE.MODAL_CAPTURING;
        brightnessFilter = false;
      }

      isSaveModalOpen = nextStage === EDIT_STAGE.MODAL_CAPTURING;

      return {
        currentEditStage: nextStage,
        brightnessFilter,
        selectedToolbarItem:
          nextStage === EDIT_STAGE.WHITE_INK ? EDIT_STAGE.WHITE_INK : null,
        isSaveModalOpen,
      };
    }),
  handlePreviousStage: () =>
    set(() => {
      const currentEditStage = get().currentEditStage;
      const hasMask = get().hasMask;
      const productSpec = get().productSpec;
      const hasWhiteInk = productSpec?.is_white_ink ?? true;

      let previousStage = currentEditStage;

      if (currentEditStage === EDIT_STAGE.MODAL_CAPTURING) {
        previousStage = hasWhiteInk
          ? EDIT_STAGE.WHITE_INK
          : hasMask
          ? EDIT_STAGE.IMAGE
          : EDIT_STAGE.DIE_CUTTING;
      } else if (currentEditStage === EDIT_STAGE.WHITE_INK) {
        previousStage = hasMask ? EDIT_STAGE.IMAGE : EDIT_STAGE.DIE_CUTTING;
      } else if (currentEditStage === EDIT_STAGE.DIE_CUTTING) {
        previousStage = EDIT_STAGE.IMAGE;
      }

      return {
        currentEditStage: previousStage,
        brightnessFilter: previousStage === EDIT_STAGE.WHITE_INK,
        selectedToolbarItem: null,
        isSaveModalOpen: false,
      };
    }),
});
