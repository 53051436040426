import {detectTransparentRegionCorners} from './contour';
import {bilinearWarpSystem} from './bilinearWarpSystem';
const appConfig = require('../../../data.json');

// 常數配置
const CONFIG = {
  SHAPE: {
    ROUNDED_RECTANGLE: {
      MARGIN_RATIO: 0.17,
      TYPE: 'roundedRectangle',
    },
    RECTANGLE: {
      MARGIN_RATIO: 0.12,
      TYPE: 'rectangle',
    },
    CIRCLE: {
      MARGIN_RATIO: 0.11,
      MARGIN_RATIO_FOR_SMALL_SCREEN: 0.1,
      TYPE: 'circle',
    },
  },
  CORNER_MARKER: {
    RADIUS: 5,
    COLOR: 'red',
  },
  BACKGROUND: {
    COLOR: '#FFFFFF',
  },
  CANVAS: {
    FORMAT: 'image/png',
  },
};

// 基礎圖片載入函數
export const loadImage = (src) => {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.crossOrigin = 'anonymous';
    img.onload = () => resolve(img);
    img.onerror = reject;
    img.src = src;
  });
};

const createCanvas = (width, height) => {
  const canvas = document.createElement('canvas');
  canvas.width = width;
  canvas.height = height;
  return canvas;
};

const setWhiteBackground = (ctx, width, height) => {
  ctx.fillStyle = CONFIG.BACKGROUND.COLOR;
  ctx.fillRect(0, 0, width, height);
};

// 繪製角落標記
const drawCornerMarkers = (ctx, points) => {
  ctx.fillStyle = CONFIG.CORNER_MARKER.COLOR;
  const cornerPoints = [
    {x: points.x1, y: points.y1},
    {x: points.x2, y: points.y2},
    {x: points.x3, y: points.y3},
    {x: points.x4, y: points.y4},
  ];

  cornerPoints.forEach((point) => {
    ctx.beginPath();
    ctx.arc(point.x, point.y, CONFIG.CORNER_MARKER.RADIUS, 0, 2 * Math.PI);
    ctx.fill();
  });
};

// 計算邊距
const calculateMargins = (sortedPoints, shapeType, dimensions, groupSize) => {
  const {width, height} = dimensions;
  const xDistance = Math.abs(sortedPoints.x2 - sortedPoints.x1);
  const yDistance = Math.abs(sortedPoints.y3 - sortedPoints.y1);
  let marginRatio = CONFIG.SHAPE.RECTANGLE.MARGIN_RATIO;

  if (shapeType === CONFIG.SHAPE.ROUNDED_RECTANGLE.TYPE) {
    return {
      marginX: xDistance * CONFIG.SHAPE.ROUNDED_RECTANGLE.MARGIN_RATIO,
      marginY: yDistance * CONFIG.SHAPE.ROUNDED_RECTANGLE.MARGIN_RATIO,
    };
  }

  if (shapeType === CONFIG.SHAPE.CIRCLE.TYPE) {
    marginRatio =
      groupSize === 400
        ? CONFIG.SHAPE.CIRCLE.MARGIN_RATIO_FOR_SMALL_SCREEN
        : CONFIG.SHAPE.CIRCLE.MARGIN_RATIO;
  }

  const avgXDistance =
    (sortedPoints.x2 - sortedPoints.x1 + sortedPoints.x3 - sortedPoints.x4) / 2;
  const avgYDistance =
    (sortedPoints.y3 - sortedPoints.y1 + sortedPoints.y4 - sortedPoints.y2) / 2;

  return {
    marginX: avgXDistance * ((avgXDistance / width) * marginRatio),
    marginY: avgYDistance * ((avgYDistance / height) * marginRatio),
  };
};

// 擴展點位
const expandPoints = (points, margins) => {
  const {marginX, marginY} = margins;
  return {
    x1: points.x1 - marginX,
    y1: points.y1 - marginY,
    x2: points.x2 + marginX,
    y2: points.y2 - marginY,
    x3: points.x3 + marginX,
    y3: points.y3 + marginY,
    x4: points.x4 - marginX,
    y4: points.y4 + marginY,
  };
};

let cornersCache = null;
let sortedPointsCache = null;
let marginsCache = null;
let groupSizeCache = null;

// 主要合併圖片函數
export const mergeImages = async (
  previewImg,
  groupImg,
  {points},
  groupSize = 600,
) => {
  try {
    if (!points?.length) {
      const canvas = createCanvas(previewImg.width, previewImg.height);
      const ctx = canvas.getContext('2d');
      ctx.drawImage(previewImg, 0, 0);
      return canvas.toDataURL(CONFIG.CANVAS.FORMAT);
    }

    const canvas = createCanvas(previewImg.width, previewImg.height);
    const ctx = canvas.getContext('2d');
    setWhiteBackground(ctx, canvas.width, canvas.height);

    // 只在需要時重新計算
    if (!cornersCache) {
      cornersCache = await detectTransparentRegionCorners(previewImg);
    }

    if (!sortedPointsCache) {
      sortedPointsCache = sortPointsAndReturnObj(cornersCache.corners);
    }

    if (!marginsCache || groupSize !== groupSizeCache) {
      marginsCache = calculateMargins(
        sortedPointsCache,
        cornersCache.shapeType,
        {
          width: previewImg.width,
          height: previewImg.height,
        },
        groupSize,
      );
      groupSizeCache = groupSize;
    }

    const expandedPoints = expandPoints(sortedPointsCache, marginsCache);

    const warpedImage = await bilinearWarpSystem({
      ...expandedPoints,
      width: previewImg.width,
      height: previewImg.height,
      image_src: groupImg,
      shapeType: cornersCache.shapeType,
    });

    const warpedImg = await loadImage(warpedImage);
    ctx.drawImage(warpedImg, 0, 0, previewImg.width, previewImg.height);
    ctx.drawImage(previewImg, 0, 0, previewImg.width, previewImg.height);

    if (appConfig.name?.toLowerCase().includes('dev')) {
      drawCornerMarkers(ctx, sortedPointsCache);
    }

    return canvas.toDataURL(CONFIG.CANVAS.FORMAT);
  } catch (error) {
    console.error('合併圖片時發生錯誤:', error);
    throw error;
  }
};

// 點位排序函數
const sortPointsAndReturnObj = (points) => {
  if (!Array.isArray(points) || points.length !== 4) {
    throw new Error('points 必須是長度為 4 的陣列');
  }

  points.sort((p1, p2) => p1.y - p2.y);
  const top = points.slice(0, 2).sort((p1, p2) => p1.x - p2.x);
  const bottom = points.slice(2, 4).sort((p1, p2) => p1.x - p2.x);

  const [leftTop, rightTop] = top;
  const [leftBottom, rightBottom] = bottom;

  return {
    x1: leftTop.x,
    y1: leftTop.y,
    x2: rightTop.x,
    y2: rightTop.y,
    x3: rightBottom.x,
    y3: rightBottom.y,
    x4: leftBottom.x,
    y4: leftBottom.y,
  };
};

// 新增清除快取的方法
export const clearImageCache = () => {
  cornersCache = null;
  sortedPointsCache = null;
  marginsCache = null;
  groupSizeCache = null;
};
