import React from 'react';
import styled from 'styled-components';
import {useDesignsStore} from '../../../Stores';

const DrawerWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: ${(props) =>
    props.isDrawerOpen ? 'rgba(0,0,0,0.35)' : 'transparent'};
  pointer-events: ${(props) => (props.isDrawerOpen ? 'auto' : 'none')};
  z-index: 99;
  transition: background 0.3s ease-in-out;
`;

const DrawerContainer = styled.div`
  position: absolute;
  top: 0px;
  left: 0;
  bottom: 0;
  width: 254px;
  background-color: white;
  z-index: 100;
  transform: translateX(${(props) => (props.isOpen ? '0' : '-100%')});
  transition: all 0.3s ease-in-out;
  box-shadow: ${(props) =>
    props.isOpen ? '4px 0 8px -4px rgba(0, 0, 0, 0.1)' : 'none'};
  opacity: ${(props) => (props.isOpen ? '1' : '0')};

  @starting-style {
    opacity: 0;
    transform: translateX(-100%);
  }
`;

const DrawerList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  max-height: calc(100% - 96px);
  overflow-y: auto;
  @media (max-width: 1441px) {
    max-height: calc(100% - 72px);
  }
`;

const DrawerItem = styled.li`
  display: flex;
  align-items: center;
  margin: 8px;
  padding: 8px;
  cursor: pointer;
  background-color: ${(props) =>
    props.isSelected ? '#f1fcf8' : 'transparent'};
  position: relative;
  z-index: ${(props) => props.style?.zIndex || 'auto'};
  border-radius: 12px;
  &:hover {
    background-color: #f7f7f7;
  }

  img {
    width: 40px;
    height: 40px;
    border-radius: 8px;
    background-color: #f8f8f8;
    overflow: hidden;
    object-fit: cover;
    margin-right: 8px;
  }

  p {
    font-size: 14px;
    color: #000;
    margin: 0;
  }
  span {
    display: inline-flex;
    width: 8px;
    height: 8px;
    background-color: #eea23e;
    border-radius: 50%;
    margin-left: auto;
    margin-right: 8px;
  }
`;

function WarningDrawer() {
  const {
    isWarningDrawerOpen,
    selectedId,
    setSelectedId,
    setIsWarningDrawerOpen,
    getLowResolutionElements,
    setSelectedToolbarItem,
  } = useDesignsStore();

  const lowResolutionElements = getLowResolutionElements
    ? getLowResolutionElements()
    : [];

  const handleOutsideClick = (e) => {
    if (e.target === e.currentTarget) {
      setIsWarningDrawerOpen(false);
    }
  };

  const handleDrawerItemClick = (id) => {
    setSelectedId(id);
    setIsWarningDrawerOpen(false);
    setSelectedToolbarItem('image');
  };

  return (
    <DrawerWrapper
      isDrawerOpen={isWarningDrawerOpen}
      onClick={handleOutsideClick}>
      <DrawerContainer isOpen={isWarningDrawerOpen}>
        <DrawerList>
          {lowResolutionElements.map((element) => (
            <DrawerItem
              key={element.id}
              isSelected={selectedId === element.id}
              onClick={() => handleDrawerItemClick(element.id)}>
              {element.type === 'image' && (
                <img src={element.src} alt="warning item thumbnail" />
              )}
              <p>
                {element.lowResolutionType === 'upload'
                  ? '上傳的圖片解析度過低'
                  : '放大後解析度過低'}
              </p>
              <span />
            </DrawerItem>
          ))}
        </DrawerList>
      </DrawerContainer>
    </DrawerWrapper>
  );
}

export default WarningDrawer;
