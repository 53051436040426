import React, {useContext, useState} from 'react';
import styled, {css} from 'styled-components';
import Button from '../Widgets/Buttons';
import {Context} from '../../../AppContext';
import {
  UndoOutlined,
  RedoOutlined,
  InfoCircleOutlined,
} from '@ant-design/icons';
import {Spin, Modal, Badge, Tooltip} from 'antd';
import {useDesignsStore} from '../../../Stores';
import {navigate} from 'gatsby';
const appConfig = require('../../../data.json');

function NavBar() {
  const app = useContext(Context);
  const {
    handleNextStage,
    handlePreviousStage,
    currentEditStage,
    mergedImage,
    generatePreviewImage,
    isUploading,
    isPreviewLoading,
    captureScreenshot,
    captureWhiteInkImage,
    product,
    productSpec,
    canvasElements,
    undo,
    redo,
    history = [[]],
    currentHistoryIndex = 0,
    getLowResolutionElements,
    setIsWarningDrawerOpen,
    setIsLayersOpen,
    isWarningDrawerOpen,
  } = useDesignsStore();

  const [isModalVisible, setIsModalVisible] = useState(false);

  const isLoading = isPreviewLoading || isUploading;
  const isDisabled = canvasElements?.length === 0;

  const canUndo = currentHistoryIndex > 0;
  const canRedo = currentHistoryIndex < history?.length - 1;

  const lowResolutionElements = getLowResolutionElements
    ? getLowResolutionElements()
    : [];

  const handleNextBtnOnClick = async () => {
    if (isDisabled) return;
    try {
      switch (currentEditStage) {
        case 'image':
          await captureScreenshot(app);
          if (!productSpec?.is_white_ink && !mergedImage) {
            setTimeout(() => {
              generatePreviewImage();
            }, 500);
          }
          handleNextStage();
          break;

        case 'whiteInk':
          if (!mergedImage) {
            setTimeout(() => {
              generatePreviewImage();
            }, 500);
          }
          await captureWhiteInkImage(app);
          handleNextStage();
          break;

        default:
          handleNextStage();
          break;
      }
    } catch (error) {
      console.error('處理下一步時發生錯誤:', error);
    }
  };

  const buttonText = currentEditStage === 'whiteInk' ? '儲存' : '下一步';
  const showPreviousButton = currentEditStage !== 'image';

  const handleCloseClick = () => {
    setIsModalVisible(true);
  };

  const handleModalConfirm = () => {
    setIsModalVisible(false);
    navigate(-1);
  };

  const handleModalCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <NavBarContainer>
      <LeftSection>
        <div>
          <img src="/images/designs/logo.svg" alt="logo" />
        </div>
        <ProductName isWarningDrawerOpen={isWarningDrawerOpen}>
          {product?.name}
          {lowResolutionElements.length > 0 && (
            <Tooltip
              title="錯誤提示"
              onClick={() => {
                setIsWarningDrawerOpen(true);
                setIsLayersOpen(false);
              }}>
              <Badge small count={lowResolutionElements.length}>
                <InfoCircleOutlined style={{marginLeft: 6}} />
              </Badge>
            </Tooltip>
          )}
        </ProductName>
        <IconGroup>
          <IconWrapper
            icon={UndoOutlined}
            onClick={undo}
            disabled={!canUndo}
            style={{marginRight: 20}}
          />
          <IconWrapper icon={RedoOutlined} onClick={redo} disabled={!canRedo} />
        </IconGroup>
      </LeftSection>
      <RightSection>
        <Button style={{marginRight: 16}} onClick={handleCloseClick}>
          關閉
        </Button>
        {showPreviousButton && (
          <Button style={{marginRight: 16}} onClick={handlePreviousStage}>
            上一步
          </Button>
        )}
        <ButtonWrapper>
          <Spin spinning={isLoading}>
            <Button
              primary
              onClick={handleNextBtnOnClick}
              disabled={isDisabled}>
              {buttonText}
            </Button>
          </Spin>
        </ButtonWrapper>
      </RightSection>

      <StyledModal
        title="確認關閉"
        open={isModalVisible}
        onOk={handleModalConfirm}
        onCancel={handleModalCancel}
        okText="確認"
        cancelText="取消">
        <p>關閉後將不會儲存目前的編輯內容，確定要關閉嗎？</p>
      </StyledModal>
    </NavBarContainer>
  );
}

const StyledIcon = styled.span`
  &&.anticon {
    color: ${(props) => (props.disabled ? 'rgba(0, 0, 0, 0.25)' : 'inherit')};
    cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  }
`;

const IconWrapper = ({icon: Icon, ...props}) => (
  <StyledIcon as={Icon} {...props} />
);

const NavBarContainer = styled.div`
  --border-color: #d8dbdf;
  --text-color: #000;

  flex: 0 0 100%;
  max-width: 100%;
  height: 88px;
  display: flex;
  justify-content: space-between;
  background-color: #fff;
  padding: 16px 24px;
  border-bottom: 1px solid var(--border-color);
  @media (max-width: 1441px) {
    height: 64px;
    padding: 12px 16px;
  }
`;

const LeftSection = styled.div`
  display: flex;
  align-items: center;
`;

const RightSection = styled.div`
  margin-left: auto;
  display: flex;
  align-items: center;
  @media (max-width: 1441px) {
    button {
      min-width: 72px;
      padding: 8px 0;
      font-size: 14px;
      border-radius: 8px;
    }
  }
`;

const ProductName = styled.div`
  padding: 0 16px;
  border-right: 1px solid #f1f1f1;
  border-left: 1px solid #f1f1f1;
  margin: 0 16px;
  font-size: 24px;
  font-weight: 500;
  color: var(--text-color);
  .anticon {
    font-size: 24px;
    color: #666;
    transition: color 0.3s;
    padding: 4px;
    border-radius: 8px;
    border: 1px solid transparent;

    ${(props) =>
      props.isWarningDrawerOpen &&
      css`
        border-color: #ffe4c0;
        background-color: #fff8eb;
      `}

    &:hover {
      color: ${appConfig.colors.main};
    }
  }

  .ant-badge {
    .ant-badge-dot {
      box-shadow: none;
      width: 8px;
      height: 8px;
    }
    .ant-badge-count {
      background-color: #eea23e;
      top: 0;
      right: 0;
    }
  }

  @media (max-width: 1441px) {
    font-size: 18px;

    .anticon {
      font-size: 16px;
    }

    .ant-badge .ant-badge-dot {
      width: 6px;
      height: 6px;
    }
  }
`;

const IconGroup = styled.div`
  display: flex;
  align-items: center;
  font-size: 20px;
  cursor: pointer;
  color: var(--text-color);
`;

const ButtonWrapper = styled.div`
  .ant-spin-container {
    display: inline-block;
  }
  .ant-spin {
    max-height: unset;
  }
  button {
    &:disabled {
      background-color: #f5f5f5;
      border-color: #d9d9d9;
      color: rgba(0, 0, 0, 0.25);
      cursor: not-allowed;

      &:hover {
        background-color: #f5f5f5;
        border-color: #d9d9d9;
        color: rgba(0, 0, 0, 0.25);
      }
    }
  }
`;

const StyledModal = styled(Modal)`
  .ant-modal-close {
    color: black;
    top: 10px;
    right: 10px;
    .ant-modal-close-x {
      font-size: 20px;
    }
  }
  .ant-modal-content {
    display: flex;
    flex-direction: column;
    border-radius: 10px;
  }
  .ant-modal-header {
    padding: 24px 24px 0;
    background-color: #fff;
    border-radius: 16px 16px 0 0;
    border-bottom: none;
    .ant-modal-title {
      font-size: 18px;
      font-weight: 700;
      color: #000;
      line-height: 1.58;
      letter-spacing: 2px;
    }
  }
  .ant-modal-body {
    padding: 24px;
  }
  .ant-modal-footer {
    padding: 20px 32px 24px;
    border-top: none;
    .ant-btn {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      width: 64px;
      height: 40px;
      border-radius: 10px;
      font-size: 14px;
      font-weight: 500;
      color: #fafafa;
      padding: 0;
      border: 1px solid transparent;
      background-color: #ccc;
      cursor: pointer;
      &.ant-btn-primary {
        background-color: ${appConfig.colors.main};
        color: white;
        &:hover {
          color: #fafafa;
          opacity: 0.75;
        }
      }
    }
  }
`;

export default NavBar;
