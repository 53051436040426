import React from 'react';
import styled from 'styled-components';
import GuideLine from '../../GuideLine';
import PanelHeader from '../../../Widgets/PanelHeader';
import {useDesignsStore} from '../../../../../Stores';
import {ReactComponent as DeleteIcon} from '../../../Assets/icons/delete.svg';
import ImageUploader from '../../ImageUploader';
import Edit from './Edit';
import {UploadOutlined} from '@ant-design/icons';
const appConfig = require('../../../../../data.json');

const ImageContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const NotesButton = styled.div`
  font-weight: 500;
  text-decoration: underline;
  color: ${appConfig.designsTheme.primary};
  cursor: pointer;
`;

const ImageUploadWrapper = styled.div`
  h2 {
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 16px;
  }
`;

const ImageUploadContainer = styled.ul`
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
  list-style: none;
  padding: 0;
  margin: 0;
`;

const ImageUploadItem = styled.li`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: calc(33% - 12px);
  max-width: calc(33% - 12px);
  border-radius: 12px;
  overflow: hidden;
  cursor: pointer;
  aspect-ratio: 1/1;
  &:first-child {
    background-color: ${appConfig.designsTheme.primaryBG};
  }
  img {
    aspect-ratio: 1/1;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  > span {
    width: 100%;
    height: 100%;
    > .ant-upload {
      width: 100%;
      height: 100%;
    }
  }
`;

const StyledDeleteIcon = styled(DeleteIcon)`
  position: absolute;
  top: 12px;
  right: 12px;
  width: 24px;
  height: 24px;
  cursor: pointer;
  z-index: 10;
`;

const LowResolutionWarning = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #d78100;
  text-align: center;
  font-size: 14px;
  font-weight: 500;
`;

function Image() {
  const {
    selectedId,
    setSelectedId,
    setSelectedToolbarItem,
    uploadedImages,
    removeUploadedImage,
    addCanvasElement,
  } = useDesignsStore();

  const handlePanelHeaderOnClick = () => {
    if (selectedId) {
      setSelectedToolbarItem('image');
    } else {
      setSelectedToolbarItem('default');
    }
    setSelectedId(null);
  };

  const handleImageClick = (image) => {
    addCanvasElement({
      id: Date.now().toString(),
      type: 'image',
      src: image.url,
      name: image.name,
    });
  };

  return (
    <ImageContainer>
      <PanelHeader onClick={handlePanelHeaderOnClick}>編輯圖片</PanelHeader>
      {selectedId ? (
        <Edit />
      ) : (
        <>
          <ImageUploadWrapper>
            <h2>圖片庫</h2>
            <ImageUploadContainer>
              <ImageUploadItem>
                <ImageUploader>
                  <UploadOutlined
                    style={{
                      fontSize: '24px',
                      color: appConfig.designsTheme.primary,
                    }}
                  />
                </ImageUploader>
              </ImageUploadItem>
              {uploadedImages.map((image) => (
                <ImageUploadItem key={image.id}>
                  <StyledDeleteIcon
                    onClick={(e) => {
                      e.stopPropagation();
                      removeUploadedImage(image.id);
                    }}
                  />
                  <img
                    src={image.url}
                    alt="bg"
                    onClick={() => handleImageClick(image)}
                  />
                  {image.isLowResolution && (
                    <LowResolutionWarning>解析度不足</LowResolutionWarning>
                  )}
                </ImageUploadItem>
              ))}
            </ImageUploadContainer>
          </ImageUploadWrapper>
          <GuideLine />
          <NotesButton onClick={() => setSelectedToolbarItem('notes')}>
            查看完稿須知
          </NotesButton>
        </>
      )}
    </ImageContainer>
  );
}

export default Image;
