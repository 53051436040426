// src/Stores/designsStore.js
import create from 'zustand';
import {pipe} from 'lodash/fp';
import {createPreviewMiddleware} from './middleware/designs/generateMiddleware';
import {
  createToolbarSlice,
  createCanvasSlice,
  createPreviewSlice,
  createUploadSlice,
  createStageSlice,
  createTransformSlice,
  createAuthSlice,
  createResetSlice,
} from './slices/designs';

const isBrowser = typeof window !== 'undefined';

// 組合所有的 middleware
const createMiddleware = pipe(
  createPreviewMiddleware,
  // 這裡可以繼續添加其他 middleware
);

const useDesignsStore = create(
  createMiddleware((set, get) => {
    if (!isBrowser) {
      return {};
    }

    return {
      isInitializing: false,
      setIsInitializing: (loading) => set({isInitializing: loading}),
      ...createToolbarSlice(set, get),
      ...createCanvasSlice(set, get),
      ...createPreviewSlice(set, get),
      ...createUploadSlice(set, get),
      ...createStageSlice(set, get),
      ...createTransformSlice(set, get),
      ...createAuthSlice(set, get),
      ...createResetSlice(set),
    };
  }),
);

export default useDesignsStore;
