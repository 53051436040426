import React, {useEffect, useContext, useState, useRef} from 'react';
import styled from 'styled-components';
import {useDesignsStore} from '../../Stores';
import {Context} from '../../AppContext';
import Canvas from './Layouts/Canvas';
import NavBar from './Layouts/NavBar';
import SidePanel from './Layouts/SidePanel';
import ToolBar from './Layouts/ToolBar';
import OutOfBoundsWarning from './Layouts/OutOfBoundsWarning';
import ActionsBar from './Layouts/ActionsBar';
import Layers from './Components/Layers';
import {ReactComponent as LayerIcon} from './Assets/icons/layer.svg';
import SaveDesignModal from './Components/SaveDesignModal';
import {Spin, Modal} from 'antd';
import {navigate} from '@reach/router';
import ResolutionWarning from './Components/ResolutionWarning';
import WarningDrawer from './Components/WarningDrawer';
const appConfig = require('../../data.json');

function Designs() {
  const app = useContext(Context);
  const {
    isLayersOpen,
    setIsLayersOpen,
    isAnyElementOutOfBounds,
    currentEditStage,
    isInitializing,
    resetStore,
    isWarningDrawerOpen,
    updateGroupSize,
  } = useDesignsStore();
  const [hasShownSizeWarning, setHasShownSizeWarning] = useState(false);
  const containerRef = useRef(null);

  const showEditingTools = currentEditStage === 'image' && !isWarningDrawerOpen;

  useEffect(() => {
    let currentModal = null;
    if (!containerRef.current) return;

    const requestFullScreen = () => {
      const element = document.documentElement;
      if (element.requestFullscreen) {
        element.requestFullscreen();
      } else if (element.webkitRequestFullscreen) {
        element.webkitRequestFullscreen();
      } else if (element.msRequestFullscreen) {
        element.msRequestFullscreen();
      }
    };

    const handleResize = () => {
      const containerWidth = containerRef.current.clientWidth;

      // 更新 groupSize
      updateGroupSize(containerWidth < 1441 ? 400 : 600);

      // 處理螢幕尺寸警告
      if (containerWidth >= 1280 && currentModal) {
        currentModal.destroy();
        currentModal = null;
        setHasShownSizeWarning(false);
        return;
      }

      if (containerWidth < 1280 && !currentModal) {
        currentModal = Modal.warning({
          title: '裝置尺寸不支援',
          content: (
            <div>
              <p>為了提供最佳使用體驗，本編輯器僅支援 13 吋以上的裝置</p>
              <p>建議您：</p>
              <ul>
                <li>手動將瀏覽器視窗最大化（按視窗右上角的最大化按鈕）</li>
                <li>點擊下方「全螢幕模式」使用完整畫面</li>
                <li>或使用更大的螢幕繼續操作</li>
              </ul>
            </div>
          ),
          okText: '返回產品列表',
          cancelText: '全螢幕模式',
          centered: true,
          okCancel: true,
          onOk: () => navigate('/products'),
          onCancel: () => {
            currentModal.destroy();
            currentModal = null;
            requestFullScreen();
          },
        });
      }
    };

    const resizeObserver = new ResizeObserver(handleResize);
    resizeObserver.observe(containerRef.current);

    return () => {
      resizeObserver.disconnect();
      if (currentModal) {
        currentModal.destroy();
      }
      resetStore();
    };
  }, [updateGroupSize, navigate, resetStore]);

  useEffect(() => {
    (async () => {
      if (!app.state.user) {
        try {
          app.actions.setAutoLoginLoading(true);
          await app.actions.autoLogin();
        } catch (err) {
          console.warn('autologin error', err);
        }
        app.actions.setAutoLoginLoading(false);
      }
    })();
  }, [app.actions, app.state.user]);

  return (
    <DesignEditorContainer ref={containerRef}>
      <NavBar />
      <MainContent isAnyElementOutOfBounds={isAnyElementOutOfBounds}>
        {!isLayersOpen && showEditingTools && (
          <LayersButton onClick={() => setIsLayersOpen(!isLayersOpen)}>
            <LayerIcon />
          </LayersButton>
        )}
        {showEditingTools && <Layers />}
        {isWarningDrawerOpen && <WarningDrawer />}
        <CanvasContainer>
          <OutOfBoundsWarning />
          {showEditingTools && !isLayersOpen && <ActionsBar />}
          <Canvas />
          {showEditingTools && <ToolBar />}
        </CanvasContainer>
        <SidePanel />
      </MainContent>
      <SaveDesignModal />
      <ResolutionWarning />
      {isInitializing && (
        <SpinContainer>
          <Spin spinning={isInitializing} size="large" />
        </SpinContainer>
      )}
    </DesignEditorContainer>
  );
}

const SpinContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
`;

const DesignEditorContainer = styled.div`
  position: relative;
  display: flex;
  flex-wrap: wrap;
  height: 100vh;
  background-color: ${appConfig.designsTheme.editorBackground};
`;

const CanvasContainer = styled.div`
  position: relative;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

const MainContent = styled.div`
  position: relative;
  display: flex;
  flex: 1;
  height: calc(100vh - 88px);
  transition: height 0.3s ease-in-out;
  @media (max-width: 1441px) {
    height: calc(100vh - 64px);
  }
`;

const LayersButton = styled.div`
  position: absolute;
  top: 68px;
  left: 24px;
  width: 48px;
  height: 48px;
  padding: 8px;
  background-color: white;
  border-radius: 8px;
  box-shadow: 1px 2px 10px 0px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  z-index: 100;

  /* 加入動畫效果 */
  opacity: 1;
  transform: translateX(0);
  transition: opacity 1s, transform 1s;

  @starting-style {
    opacity: 0;
    transform: translateX(-20px);
  }

  @media (max-width: 1441px) {
    top: 48px;
    left: 12px;
    width: 40px;
    height: 40px;
    padding: 8px;
  }
`;

export default Designs;
