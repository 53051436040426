export const createUploadSlice = (set, get) => ({
  uploadedImages: [],
  showResolutionWarningModal: false,
  resolutionWarningType: null,
  uploadDialogOpen: false,

  setUploadDialogOpen: (open) => set({uploadDialogOpen: open}),

  setResolutionWarningModal: (type) =>
    set({showResolutionWarningModal: true, resolutionWarningType: type}),

  closeResolutionWarningModal: () => set({showResolutionWarningModal: false}),

  clearResolutionWarningType: () => set({resolutionWarningType: null}),

  setUploadedImages: (newImage) =>
    set(() => {
      const currentImages = get().uploadedImages;
      return {
        uploadedImages: [...currentImages, newImage],
      };
    }),
  removeUploadedImage: (idToRemove) =>
    set(() => {
      const currentImages = get().uploadedImages;
      const currentElements = get().canvasElements;

      setTimeout(() => get().generatePreviewImage(), 0);

      return {
        uploadedImages: currentImages.filter(
          (image) => image.id !== idToRemove,
        ),
        canvasElements: currentElements.filter(
          (element) => element.id !== idToRemove,
        ),
      };
    }),
});
