import React from 'react';
import styled from 'styled-components';

const COLORS = [
  '#BF332B',
  '#BB0415',
  '#F3C6DA',
  '#ECA4C3',
  '#9A142B',
  '#590033',
  '#400253',
  '#272878',
  '#072769',
  '#12114C',
  '#A8D7E9',
  '#579ED4',
  '#2E5799',
  '#233655',
  '#009199',
  '#41912B',
  '#2A6E3A',
  '#00451D',
  '#3D4448',
  '#D8DBDF',
];

const ColorPickerContainer = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 16px;
`;

const ColorItem = styled.div`
  position: relative;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  background-color: ${({color}) => color};
  cursor: pointer;
  padding: 2px;
  &::before,
  &::after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    border-radius: 50%;
    transition: all 0.3s;
  }
  &::before {
    border: ${({active}) =>
      active ? '4px solid white' : '4px solid transparent'};
  }
  &::after {
    border: ${({active}) =>
      active ? '2px solid #6B7280' : '2px solid transparent'};
  }
`;

function ColorPicker({selectedColor, onClick}) {
  return (
    <ColorPickerContainer>
      {COLORS.map((color) => (
        <ColorItem
          key={color}
          color={color}
          active={color === selectedColor}
          onClick={() => onClick(color)}
        />
      ))}
    </ColorPickerContainer>
  );
}

export default ColorPicker;
