export const createTransformSlice = (set, get) => ({
  fitElementToStage: (id, fitType, stageWidth, stageHeight) => {
    const element = get().canvasElements.find((el) => el.id === id);
    if (!element) return;

    let updates = {};

    if (fitType === 'height') {
      const newHeight = stageHeight;
      updates = {
        height: newHeight,
        y: 0,
      };
    } else if (fitType === 'width') {
      const newWidth = stageWidth;
      updates = {
        width: newWidth,
        x: 0,
      };
    }

    get().updateCanvasElement(id, updates);
  },

  rotateElement: (id, direction) => {
    const element = get().canvasElements.find((el) => el.id === id);
    if (!element) return;

    const currentRotation = element.rotation || 0;
    const newRotation = currentRotation + (direction === 'left' ? -45 : 45);

    get().updateCanvasElement(id, {
      rotation: newRotation % 360,
    });
  },

  centerElement: (id, centerType, stageWidth, stageHeight) => {
    const element = get().canvasElements.find((el) => el.id === id);
    if (!element) return;

    let updates = {};
    if (centerType === 'horizontal') {
      updates = {x: (stageWidth - element.width) / 2};
    } else if (centerType === 'vertical') {
      updates = {y: (stageHeight - element.height) / 2};
    }

    get().updateCanvasElement(id, updates);
  },

  reflectElement: (id, direction) => {
    const element = get().canvasElements.find((el) => el.id === id);
    if (!element) return;

    const currentScaleX = element.scaleX || 1;
    const currentScaleY = element.scaleY || 1;

    get().updateCanvasElement(id, {
      scaleX: direction === 'horizontal' ? -currentScaleX : currentScaleX,
      scaleY: direction === 'vertical' ? -currentScaleY : currentScaleY,
    });
  },
});
