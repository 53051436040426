import React, {useState} from 'react';
import Item from '../../../Widgets/Item';
import PanelHeader from '../../../Widgets/PanelHeader';
import styled from 'styled-components';
import {ReactComponent as FontIcon} from '../../../Assets/icons/font.svg';
import {ReactComponent as ColorIcon} from '../../../Assets/icons/colors.svg';
import {ReactComponent as AlignIcon} from '../../../Assets/icons/align.svg';
import {useDesignsStore} from '../../../../../Stores';
import Font from './Font';
import Colors from './Colors';
import Alignment from './Alignment';
import ActionButton from '../../../Widgets/ActionButton';
import {ReactComponent as DeleteIcon} from '../../../Assets/icons/delete.svg';
const TEXT_TAB_ITEMS = [
  {
    key: 'family',
    icon: <FontIcon />,
    label: '字型',
    component: Font,
  },
  {
    key: 'color',
    icon: <ColorIcon />,
    label: '顏色',
    component: Colors,
  },
  {
    key: 'align',
    icon: <AlignIcon />,
    label: '對齊',
    component: Alignment,
  },
];

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  height: 100%;
`;

const TextTabs = styled.div`
  display: flex;
  align-items: center;
  column-gap: 12px;
  margin-bottom: 8px;
`;

const TextContent = styled.div`
  overflow-y: auto;
  flex: 1;
`;

function Text() {
  const {setSelectedToolbarItem} = useDesignsStore();
  const [currentTab, setCurrentTab] = useState('family');

  const CurrentTabComponent =
    TEXT_TAB_ITEMS.find((item) => item.key === currentTab)?.component || null;

  return (
    <TextContainer>
      <PanelHeader onClick={() => setSelectedToolbarItem('default')}>
        編輯文字
      </PanelHeader>
      <TextTabs>
        {TEXT_TAB_ITEMS.map((item) => (
          <Item
            icon={item.icon}
            text={item.label}
            active={currentTab === item.key}
            onClick={() => setCurrentTab(item.key)}
          />
        ))}
      </TextTabs>
      <TextContent>
        {CurrentTabComponent && <CurrentTabComponent />}
      </TextContent>
      <ActionButton>
        <DeleteIcon />
        刪除文字
      </ActionButton>
    </TextContainer>
  );
}

export default Text;
