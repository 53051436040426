import React from 'react';
import styled from 'styled-components';
import {CloseOutlined} from '@ant-design/icons';

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
`;

const ModalContent = styled.div`
  position: relative;
  width: 600px;
  border-radius: 12px;
  overflow: hidden;
  background-color: white;
  img {
    max-width: 100%;
    object-fit: contain;
  }
`;

const CloseButton = styled.button`
  position: absolute;
  top: 6px;
  right: 12px;
  color: black;
  font-size: 20px;
  cursor: pointer;
  border: none;
  background: none;
`;

function ImagePreviewModal({image, onClose}) {
  return (
    <ModalOverlay onClick={onClose}>
      <ModalContent onClick={(e) => e.stopPropagation()}>
        <CloseButton onClick={onClose}>
          <CloseOutlined />
        </CloseButton>
        <img src={image} alt="預覽圖" />
      </ModalContent>
    </ModalOverlay>
  );
}

export default ImagePreviewModal;
