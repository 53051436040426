import React from 'react';
import styled from 'styled-components';

const FontContainer = styled.ul`
  display: flex;
  flex-direction: column;
  justify-content: center;
  list-style: none;
  row-gap: 24px;
  padding: 0;
  margin: 0;
`;

const FontItem = styled.li`
  font-size: 20px;
  font-weight: 500;
  color: #000;
  cursor: pointer;
  text-align: left;
`;

function Font() {
  return (
    <FontContainer>
      {Array.from({length: 20}).map((_, index) => (
        <FontItem key={index}>Typography</FontItem>
      ))}
    </FontContainer>
  );
}

export default Font;
