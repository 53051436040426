import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useContext,
} from 'react';
import {
  Stage,
  Layer,
  Group,
  Image as KonvaImage,
  Rect,
  Shape,
  Transformer,
} from 'react-konva';
import {useDesignsStore} from '../../../Stores';
import {Context} from '../../../AppContext';
import URLImage from '../Components/Canvas/URLImage';
import {parseSvgPath} from '../Utils/svgParser.js';
import getSmoothContours from '../Utils/contour.js';
import {loadImage} from '../Utils/imageLoader.js';
import {debounce} from 'lodash';
import styled from 'styled-components';
import {useLocation, navigate} from '@reach/router';
import {message} from 'antd';
const appConfig = require('../../../data.json');

const CanvasWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
`;

function Canvas() {
  const app = useContext(Context);
  const {
    canvasElements,
    updateCanvasElement,
    selectedId,
    setSelectedId,
    setSelectedToolbarItem,
    currentEditStage,
    setPreviewConfig,
    setPreviewImage,
    setStageRef,
    generatePreviewImage,
    getJwtToken,
    loadDesignById,
    setProduct,
    setConfig,
    setProductSpec,
    svgDimensions,
    setSvgDimensions,
    setEditorOption,
    isInitializing,
    setIsInitializing,
    undo,
    redo,
    groupSize: GROUP_SIZE,
  } = useDesignsStore();

  const [cuttingMoldImage, setCuttingMoldImage] = useState(null);
  const [clipPath, setClipPath] = useState('');
  const [isPathLoaded, setIsPathLoaded] = useState(false);
  const [stageSize, setStageSize] = useState({width: 0, height: 0});
  const containerRef = useRef(null);

  const stageRef = useRef(null);
  const groupRef = useRef(null);

  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const designId = params.get('id');

  const debouncedCapture = useCallback(() => {
    debounce(() => {
      generatePreviewImage();
    }, 500)();
  }, [generatePreviewImage]);

  const handleNavigation = useCallback(() => {
    message.error('無法成功載入設計資料，返回產品列表頁');
    navigate('/products');
  }, []);

  useEffect(() => {
    const initializeData = async () => {
      console.log('開始初始化 Canvas...');
      setIsInitializing(true);
      try {
        await getJwtToken(app);
        console.log('JWT Token 已取得');
        if (location.state?.product) {
          console.log('設定產品:', location.state.product);
          setProduct(location.state.product, app);
        } else {
          handleNavigation();
        }
        console.log('location.state:', location.state);
        if (location.state?.config) {
          setConfig(location.state.config);
        }
        if (location.state?.designSpec) {
          console.log('設定產品規格:', location.state.designSpec);
          setProductSpec(location.state.designSpec);
        }
        if (designId) {
          await loadDesignById(designId, app);
        }

        if (location.state?.designSpec) {
          console.log('開始處理產品規格...');
          const editorOption = location.state?.designSpec?.editor_options?.find(
            (option) => option.editor_id === location.state?.specID,
          );
          console.log('尋找 Config 對應素材...');
          console.log('editorOption', editorOption);
          setEditorOption(editorOption);
          // 載入 SVG 資源
          const response = await fetch(editorOption?.mask_image);
          const svgText = await response.text();
          console.log('SVG 已載入');
          const parser = new DOMParser();
          const svgDoc = parser.parseFromString(svgText, 'image/svg+xml');
          const svgElement = svgDoc.documentElement;

          // 處理 SVG 尺寸
          const viewBox = svgElement.getAttribute('viewBox')?.split(' ') || [];
          const width =
            parseFloat(svgElement.getAttribute('width')) ||
            parseFloat(viewBox[2]) ||
            0;
          const height =
            parseFloat(svgElement.getAttribute('height')) ||
            parseFloat(viewBox[3]) ||
            0;
          console.log('SVG 尺寸:', {width, height});
          setSvgDimensions({width, height});

          // 解析 SVG 路徑
          const pathData = parseSvgPath(
            svgText,
            GROUP_SIZE / Math.max(width, height),
          );
          if (pathData) {
            console.log('SVG 路徑已解析');
            setClipPath(pathData);
          }

          const [loadedCutting, previewImg] = await Promise.all([
            loadImage(editorOption?.die_cut_image),
            loadImage(editorOption?.preview_images[0]),
          ]);
          console.log('圖片資源載入完成');

          setCuttingMoldImage(loadedCutting);
          setIsPathLoaded(true);
          setPreviewImage(previewImg);
          const config = await getSmoothContours(previewImg);
          setPreviewConfig({points: config});
        }
      } catch (error) {
        console.error('初始化失敗:', error);
        handleNavigation();
      } finally {
        setIsInitializing(false);
      }
    };

    initializeData();
  }, [designId]);

  useEffect(() => {
    if (selectedId && !canvasElements.find((el) => el.id === selectedId)) {
      setSelectedId(null);
    }
  }, [canvasElements, selectedId, setSelectedId]);

  useEffect(() => {
    console.log('canvasElements', canvasElements);
  }, [canvasElements]);

  useEffect(() => {
    if (stageRef.current && !isInitializing) {
      setStageRef(stageRef);
      generatePreviewImage();
    }
  }, [stageRef.current, isInitializing, isPathLoaded]);

  useEffect(() => {
    const SIDE_PANEL_WIDTH = 400; // 定義側邊欄寬度

    const updateSize = () => {
      if (containerRef.current) {
        const container = containerRef.current;
        // 扣除側邊欄寬度
        const containerWidth = container.offsetWidth - SIDE_PANEL_WIDTH;
        const containerHeight = container.offsetHeight;

        setStageSize({
          width: containerWidth,
          height: containerHeight,
        });

        if (stageRef.current) {
          stageRef.current.batchDraw();
        }
      }
    };

    const debouncedUpdateSize = debounce(updateSize, 250);
    updateSize();

    window.addEventListener('resize', debouncedUpdateSize);
    return () => {
      window.removeEventListener('resize', debouncedUpdateSize);
      debouncedUpdateSize.cancel();
    };
  }, []);

  const drawClipPath = (ctx, shouldClip = false) => {
    {
      ctx.beginPath();
      ctx.rect(0, 0, GROUP_SIZE, GROUP_SIZE);
      ctx.scale(scale, scale);

      if (typeof clipPath === 'string') {
        // 處理一般的 SVG path
        const path = new Path2D(clipPath);
        if (shouldClip) {
          ctx._context.clip(path);
        } else {
          ctx._context.stroke(path);
        }
      } else if (clipPath && clipPath.type === 'rect') {
        // 處理 rect 元素
        const path = new Path2D();
        if (clipPath.rx > 0 || clipPath.ry > 0) {
          // 繪製圓角矩形
          const rx = clipPath.rx;
          const ry = clipPath.ry;
          path.moveTo(clipPath.x + rx, clipPath.y);
          path.lineTo(clipPath.x + clipPath.width - rx, clipPath.y);
          path.quadraticCurveTo(
            clipPath.x + clipPath.width,
            clipPath.y,
            clipPath.x + clipPath.width,
            clipPath.y + ry,
          );
          path.lineTo(
            clipPath.x + clipPath.width,
            clipPath.y + clipPath.height - ry,
          );
          path.quadraticCurveTo(
            clipPath.x + clipPath.width,
            clipPath.y + clipPath.height,
            clipPath.x + clipPath.width - rx,
            clipPath.y + clipPath.height,
          );
          path.lineTo(clipPath.x + rx, clipPath.y + clipPath.height);
          path.quadraticCurveTo(
            clipPath.x,
            clipPath.y + clipPath.height,
            clipPath.x,
            clipPath.y + clipPath.height - ry,
          );
          path.lineTo(clipPath.x, clipPath.y + ry);
          path.quadraticCurveTo(
            clipPath.x,
            clipPath.y,
            clipPath.x + rx,
            clipPath.y,
          );
        } else {
          // 繪製普通矩形
          path.rect(clipPath.x, clipPath.y, clipPath.width, clipPath.height);
        }
        if (shouldClip) {
          ctx._context.clip(path);
        } else {
          ctx._context.stroke(path);
        }
      }
      ctx.scale(1 / scale, 1 / scale);
    }
  };

  const checkDeselect = (e) => {
    const clickedOnEmpty = e.target === e.target.getStage();
    if (clickedOnEmpty) {
      setSelectedId(null);
    }
  };

  const handleCanvasImageOnSelect = (id) => {
    setSelectedId(id);
    setSelectedToolbarItem('image');
  };

  const handleTransformEnd = (e, element) => {
    const node = e.target;
    updateCanvasElement(element.id, {
      x: node.x() - node.width() / 2,
      y: node.y() - node.height() / 2,
      width: node.width(),
      height: node.height(),
      rotation: node.rotation(),
      scaleX: node.scaleX(),
      scaleY: node.scaleY(),
      isLowResolution: e.isLowResolution ?? false,
      lowResolutionType: e.lowResolutionType ?? null,
    });
  };

  // 計算縮放比例
  const scale =
    GROUP_SIZE /
    Math.max(svgDimensions?.width || 0, svgDimensions?.height || 0);

  useEffect(() => {
    const handleKeyDown = (e) => {
      if ((e.metaKey || e.ctrlKey) && e.key === 'z') {
        if (e.shiftKey) {
          redo();
        } else {
          undo();
        }
      }
    };

    window.addEventListener('keydown', handleKeyDown);
    return () => window.removeEventListener('keydown', handleKeyDown);
  }, [undo, redo]);

  return (
    <CanvasWrapper ref={containerRef}>
      <Stage
        ref={stageRef}
        width={stageSize.width}
        height={stageSize.height}
        onMouseDown={checkDeselect}
        onMouseUp={debouncedCapture}>
        <Layer>
          <Rect
            width={stageSize.width}
            height={stageSize.height}
            fill="#f1f1f1"
          />
          {isPathLoaded && (
            <>
              <Group
                ref={groupRef}
                id="design-group"
                x={(stageSize.width - GROUP_SIZE) / 2}
                y={(stageSize.height - GROUP_SIZE) / 2}
                clipFunc={(ctx) => drawClipPath(ctx, true)}
                listening={true}>
                <Rect
                  x={0}
                  y={0}
                  width={GROUP_SIZE}
                  height={GROUP_SIZE}
                  fill="white"
                />
                {canvasElements.map(
                  (element) =>
                    element.type === 'image' && (
                      <URLImage
                        id={element.id}
                        key={element.id}
                        {...element}
                        isSelected={
                          currentEditStage === 'image' &&
                          selectedId === element.id
                        }
                        onSelect={() =>
                          currentEditStage === 'image' &&
                          handleCanvasImageOnSelect(element.id)
                        }
                        onTransformEnd={(e) => handleTransformEnd(e, element)}
                        draggable={currentEditStage === 'image'}
                        listening={true}
                        size={{
                          width: GROUP_SIZE,
                          height: GROUP_SIZE,
                        }}
                      />
                    ),
                )}
                {cuttingMoldImage && (
                  <KonvaImage
                    id="cutting-mold-layer"
                    image={cuttingMoldImage}
                    width={GROUP_SIZE}
                    height={GROUP_SIZE}
                    listening={false}
                    opacity={currentEditStage === 'image' ? 0.95 : 1}
                  />
                )}
              </Group>
              <Shape
                x={(stageSize.width - GROUP_SIZE) / 2}
                y={(stageSize.height - GROUP_SIZE) / 2}
                sceneFunc={(ctx) => {
                  drawClipPath(ctx, false);
                }}
              />
              {selectedId && currentEditStage === 'image' && (
                <Transformer
                  ref={(selectedElement) => {
                    if (selectedElement) {
                      const node = selectedElement
                        .getStage()
                        .findOne(`#image-${selectedId}`);
                      if (node) {
                        selectedElement.nodes([node]);
                        selectedElement.getLayer().batchDraw();
                      }
                    }
                  }}
                  centeredScaling={false}
                  anchorStroke={appConfig.designsTheme.primary}
                  anchorFill={appConfig.designsTheme.primary}
                  borderStroke={appConfig.designsTheme.primary}
                  anchorCornerRadius={100}
                  anchorSize={8}
                  enabledAnchors={[
                    'top-left',
                    'top-right',
                    'bottom-left',
                    'bottom-right',
                  ]}
                  boundBoxFunc={(oldBox, newBox) => {
                    if (newBox.width < 5 || newBox.height < 5) {
                      return oldBox;
                    }
                    return newBox;
                  }}
                />
              )}
            </>
          )}
        </Layer>
      </Stage>
    </CanvasWrapper>
  );
}

export default Canvas;
