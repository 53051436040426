import React from 'react';
import styled from 'styled-components';
const appConfig = require('../../../src/data.json');

const MAX_WIDTH = appConfig.maxContentWidth;

export default function EditorTermsPage() {
  return (
    <Wrapper>
      <div className="constraint">
        <div>
          <h1>線上編輯器訂購條款及免責聲明</h1>

          <p class="terms-intro">
            為維護您的權益，請務必詳讀訂購條款及免責聲明所有條文，與理想印制電商平台交易之始，即表示您同意下列事項。
          </p>

          <p class="terms-notice">
            上傳圖檔及加入購物車結帳前，請詳閱線上編輯器服務規範，以下說明請詳閱及遵守：
          </p>

          <section class="terms-section">
            <h2>一、印刷成品免責聲明</h2>
            <div class="terms-content">
              <p>
                線上編輯器上傳檔案之印刷成果，將由您上傳之圖片像素畫質、圖片品質或解析度而定，實際檔案需求請參照每項商品設計規範及完稿須知內容，上傳適合的檔案。
              </p>

              <p>
                若使用低於線上編輯器設計規範之圖片尺寸進行編輯製作，請您重新上傳更高解析度的檔案，請注意！如果因圖片解析度不足，造成印刷成品與示意模擬圖差異該情況並非印刷瑕疵，我們將無法受理您的退換貨要求。
              </p>

              <p>
                線上編輯器成品示意模擬圖(渲染圖)，僅供成品排版預覽使用，且電腦及手機螢幕裝置與印刷成像原理不同，請勿以線上編輯器示意模擬圖作為正式印刷成品校色、位置、材質之比較依據，請同意確認後再下單。
              </p>

              <p>
                線上編輯器個人化商品，一旦成立訂單便無法修改設計樣式及訂單異動和退換貨，請確認商品規格及款式再下單，本站保留取消訂單之權利。
              </p>
            </div>
          </section>

          <section class="terms-section">
            <h2>二、印刷內容限制</h2>
            <div class="terms-content">
              <p>
                您了解並同意對於您所提供的印製內容，及理想印制電商平台因使用該內容而產生之一切法律責任，均由您完全負責。
              </p>

              <p>
                請勿印製侵害他人名譽權、隱私權、肖像權、營業秘密、商標權、著作權、專利權等智慧財產權，或其他財產權及違反任何適用法律或法規。
              </p>

              <p>
                請勿印製帶有違反公序良俗或不當之文字、圖片或任何形式的檔案，例如：外貌與身體歧視、種族歧視、誹謗侮辱、威脅性、攻擊性、不雅等內容。
              </p>

              <p>
                理想印制有權判斷您是否合法且正當的使用本線上編輯器服務，且創作與產出皆不代表本電商平台的立場，並保留取消訂單之權利。
              </p>

              <p>
                若理想印制電商平台認為您的訂單印件內容，已經違反本服務可用性條款及其精神，我們將有權未經通知您而逕行取消您的訂單，並隨時終止我們的服務。
              </p>
            </div>
          </section>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  background-color: #fff;
  flex: 1;
  padding: 40px 0;
  & .constraint {
    max-width: ${MAX_WIDTH + 40}px;
    margin: 0 auto;
    padding: 0px 20px;
    white-space: pre-wrap;
    overflow-wrap: break-word;
    h1 {
      text-align: center;
      margin-bottom: 40px;
    }
  }
`;
