import React from 'react';
import styled from 'styled-components';
import GuideLine from '../GuideLine';
import {useDesignsStore} from '../../../../Stores';
const appConfig = require('../../../../data.json');

const DefaultContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 24px;
`;

const NotesButton = styled.div`
  font-weight: 500;
  text-decoration: underline;
  color: ${appConfig.designsTheme.primary};
  cursor: pointer;
`;

function Default() {
  const {setSelectedToolbarItem} = useDesignsStore();

  return (
    <DefaultContainer>
      <GuideLine />
      <NotesButton onClick={() => setSelectedToolbarItem('notes')}>
        查看完稿須知
      </NotesButton>
    </DefaultContainer>
  );
}

export default Default;
