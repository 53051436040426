import React from 'react';
import styled from 'styled-components';
import {useDesignsStore} from '../../../Stores';

const DesignGuidelineWrapper = styled.div`
  h2 {
    font-size: 16px;
    font-weight: 700;
    color: #000;
    margin-bottom: 16px;
  }
`;

const DesignGuidelineContainer = styled.ul`
  display: flex;
  gap: 16px;
  list-style: none;
  flex-wrap: wrap;
  margin-bottom: 0px;
`;

const DesignGuidelineItem = styled.li`
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #f8f8f8;
  padding: 20px 12px;
  border-radius: 8px;
  flex: 0 0 calc(50% - 8px);
  max-width: calc(50% - 8px);
  color: #000;
  h3 {
    font-size: 14px;
    margin-bottom: 4px;
  }
  p {
    margin: 0;
    font-weight: 500;
  }
`;

function GuideLine() {
  const {svgDimensions: {width = 1200, height = 1200} = {}} = useDesignsStore();

  const DESIGN_GUIDELINE = [
    {
      key: 'dimensions',
      label: '圖片尺寸',
      value: `${width} × ${height} 像素`,
    },
    {
      key: 'size',
      label: '圖片大小',
      value: '最大不超過 20 MB',
    },
    {
      key: 'format',
      label: '圖片格式',
      value: 'JPG/JPEG/PNG',
    },
    {
      key: 'resolution',
      label: '圖片分辨率與色域',
      value: '300 DPI、RGB模式',
    },
  ];

  return (
    <DesignGuidelineWrapper>
      <h2>設計規範</h2>
      <DesignGuidelineContainer>
        {DESIGN_GUIDELINE.map((item) => (
          <DesignGuidelineItem key={item.key}>
            <h3>{item.label}</h3>
            <p>{item.value}</p>
          </DesignGuidelineItem>
        ))}
      </DesignGuidelineContainer>
    </DesignGuidelineWrapper>
  );
}

export default GuideLine;
