import React from 'react';
import styled from 'styled-components';
import {WarningFilled} from '@ant-design/icons';
import {useDesignsStore} from '../../../Stores';

const appConfig = require('../../../data.json');

const WarningContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
  background-color: ${appConfig.designsTheme.warningBar};
  color: #d78100;
  padding: 16px 24px;
  font-weight: 500;
  line-height: 24px;
  z-index: 10;
  @media (max-width: 1441px) {
    padding: 8px 12px;
    font-size: 12px;
  }
`;

const WarningMessage = styled.div`
  display: flex;
  align-items: center;

  .warning-icon {
    color: #d78100;
    font-size: 20px;
    margin-right: 16px;

    @media (max-width: 1441px) {
      font-size: 16px;
      margin-right: 8px;
    }
  }
`;

const OutOfBoundsWarning = () => {
  const {isAnyElementOutOfBounds} = useDesignsStore();

  if (!isAnyElementOutOfBounds) {
    return null;
  }

  return (
    <WarningContainer>
      {isAnyElementOutOfBounds && (
        <WarningMessage>
          <WarningFilled className="warning-icon" />
          注意：超出紅色線範圍區域外的設計稿為「出血」不納入印刷範圍，請務必將圖稿延伸出來，否則有機會露白
        </WarningMessage>
      )}
    </WarningContainer>
  );
};

export default OutOfBoundsWarning;
