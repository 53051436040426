import React, {useState} from 'react';
import styled from 'styled-components';
import {useDesignsStore} from '../../../Stores';
import Default from '../Components/SidePanel/Default';
import Image from '../Components/SidePanel/Image';
import Text from '../Components/SidePanel/Text';
import Sticker from '../Components/SidePanel/Sticker';
import Background from '../Components/SidePanel/Background';
import CuttingMold from '../Components/SidePanel/CuttingMold';
import WhiteInk from '../Components/SidePanel/WhiteInk';
import Notes from '../Components/SidePanel/Image/Notes';
import {ReactComponent as FullScreenIcon} from '../Assets/icons/full-screen.svg';
import BG from '../Assets/images/bg.png';
import ImagePreviewModal from '../Components/ImagePreviewModal';

const SidePanelContainer = styled.div`
  flex: 0 0 400px;
  max-width: 400px;
  background-color: #fff;
  padding: 24px;
  overflow: auto;
`;

const PreviewProductWrapper = styled.div`
  position: relative;
  margin-bottom: 24px;
  > img {
    width: 100%;
  }
`;

const FullScreenButton = styled.div`
  position: absolute;
  bottom: 14px;
  right: 14px;
  cursor: pointer;
`;

function SidePanel() {
  const {selectedToolbarItem, mergedImage} = useDesignsStore();

  const [isPreviewModalOpen, setIsPreviewModalOpen] = useState(false);

  const renderSidePanelContent = () => {
    switch (selectedToolbarItem) {
      case 'image':
        return <Image />;
      case 'text':
        return <Text />;
      case 'sticker':
        return <Sticker />;
      case 'background':
        return <Background />;
      case 'dieCuttingMold':
        return <CuttingMold />;
      case 'whiteInk':
        return <WhiteInk />;
      case 'notes':
        return <Notes />;
      default:
        return <Default />;
    }
  };

  return (
    <SidePanelContainer>
      <PreviewProductWrapper>
        <img src={mergedImage || BG} alt="bg" />
        <FullScreenButton onClick={() => setIsPreviewModalOpen(true)}>
          <FullScreenIcon />
        </FullScreenButton>
      </PreviewProductWrapper>
      {renderSidePanelContent()}
      {isPreviewModalOpen && mergedImage && (
        <ImagePreviewModal
          image={mergedImage}
          onClose={() => setIsPreviewModalOpen(false)}
        />
      )}
    </SidePanelContainer>
  );
}

export default SidePanel;
