import React, {useState, useContext, useEffect} from 'react';
import styled from 'styled-components';
import PanelHeader from '../../../Widgets/PanelHeader';
import {useDesignsStore} from '../../../../../Stores';
import {Modal} from 'antd';
import {Context} from '../../../../../AppContext';
import {useLocation} from '@reach/router';

const NotesContainer = styled.div``;

const NotesContent = styled.ul`
  display: flex;

  list-style: none;
  padding: 0;
  margin: 0;
  gap: 16px;
  flex-wrap: wrap;
`;

function Notes() {
  const app = useContext(Context);
  const {setSelectedToolbarItem, product} = useDesignsStore();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [noticeImages, setNoticeImages] = useState([]);

  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const designId = params.get('id');

  const handleImageClick = (image) => {
    setSelectedImage(image);
    setIsModalOpen(true);
  };

  useEffect(() => {
    if (designId) {
      app.actions.getProduct(product.id).then((response) => {
        console.log('response', response);
        const images = response.images.filter(
          (image) => image.name === 'notice',
        );
        setNoticeImages(images);
      });
    } else {
      const images = product?.images.filter((image) => image.name === 'notice');
      setNoticeImages(images);
    }
  }, [designId, product.id]);

  return (
    <NotesContainer>
      <PanelHeader onClick={() => setSelectedToolbarItem('default')}>
        完稿須知
      </PanelHeader>
      <NotesContent>
        <div>
          {noticeImages.map((img, idx) => (
            <img
              alt={`${product.name} 完稿須知 - ${idx}`}
              key={idx}
              style={{width: '100%', cursor: 'pointer'}}
              src={img.image}
              onClick={() => handleImageClick(img)}
            />
          ))}
        </div>
      </NotesContent>

      <Modal
        open={isModalOpen}
        footer={null}
        onCancel={() => setIsModalOpen(false)}
        width="80%"
        centered
        bodyStyle={{
          maxHeight: '90vh',
          overflow: 'auto',
          padding: '24px',
          textAlign: 'center',
        }}>
        <img
          src={selectedImage?.image}
          alt="完稿須知"
          style={{
            width: '100%',
            height: 'auto',
            objectFit: 'contain',
          }}
        />
      </Modal>
    </NotesContainer>
  );
}

export default Notes;
