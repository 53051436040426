import React, {useState, useEffect, useRef, useCallback} from 'react';
import {Image} from 'react-konva';
import {useDesignsStore} from '../../../../Stores';
import Konva from 'konva';
import {debounce} from 'lodash';

const URLImage = ({
  id,
  src,
  isSelected,
  onSelect,
  onTransformEnd,
  rotation = 0,
  scaleX = 1,
  scaleY = 1,
  groupPosition,
  size,
  originalSize,
  ...imageProps
}) => {
  const {
    brightnessFilter,
    currentEditStage,
    isWhiteInkActive,
    selectedId,
    setSelectedId,
    setIsAnyElementOutOfBounds,
    svgDimensions,
    setResolutionWarningModal,
    showResolutionWarningModal,
    resolutionWarningType,
    clearResolutionWarningType,
    setLastValidTransform,
    lastValidTransform,
    activeResizeImageId,
  } = useDesignsStore();
  const [image, setImage] = useState(null);
  const imageRef = useRef(null);
  const trRef = useRef(null);

  const getMaxAllowedScale = useCallback(() => {
    if (!originalSize || !svgDimensions) return 1;

    const widthRatio = originalSize.width / svgDimensions.width;
    const heightRatio = originalSize.height / svgDimensions.height;

    return Math.min(widthRatio, heightRatio);
  }, [originalSize, svgDimensions]);

  const checkScaleExceedsLimit = (newScaleX, newScaleY) => {
    const maxScale = getMaxAllowedScale();
    console.log('maxScale', maxScale);
    return Math.abs(newScaleX) > maxScale || Math.abs(newScaleY) > maxScale;
  };

  const handleTransformEnd = (e) => {
    if (!isDraggable) return;

    const node = e.target;
    const newScaleX = node.scaleX();
    const newScaleY = node.scaleY();

    console.log('newScaleX', newScaleX);
    console.log('newScaleY', newScaleY);

    if (
      imageProps.isLowResolution &&
      imageProps.lowResolutionType === 'upload'
    ) {
      onTransformEnd({
        target: node,
        isLowResolution: true,
        lowResolutionType: imageProps.lowResolutionType,
      });
      return;
    }

    const isExceedingLimit = checkScaleExceedsLimit(newScaleX, newScaleY);
    const wasExceedingLimit =
      imageProps.isLowResolution && imageProps.lowResolutionType === 'resize';

    if (isExceedingLimit && !wasExceedingLimit) {
      setLastValidTransform({
        id,
        transform: {
          scaleX,
          scaleY,
          x: imageProps.x,
          y: imageProps.y,
        },
      });
      setResolutionWarningModal('resize');
      onTransformEnd({
        target: node,
        isLowResolution: true,
        lowResolutionType: 'resize',
      });
    } else if (!isExceedingLimit) {
      setLastValidTransform({
        id,
        transform: {
          scaleX: newScaleX,
          scaleY: newScaleY,
          x: node.x() - node.width() / 2,
          y: node.y() - node.height() / 2,
        },
      });
      onTransformEnd({
        target: node,
        isLowResolution: false,
        lowResolutionType: null,
      });
    } else {
      onTransformEnd({
        target: node,
        isLowResolution: true,
        lowResolutionType: 'resize',
      });
    }
  };

  useEffect(() => {
    console.log('showResolutionWarningModal', showResolutionWarningModal);
    if (
      !showResolutionWarningModal &&
      lastValidTransform &&
      id === activeResizeImageId
    ) {
      console.log('lastValidTransform', resolutionWarningType);
      if (resolutionWarningType === 'resize') {
        const node = imageRef.current;
        if (node) {
          node.scaleX(lastValidTransform.scaleX);
          node.scaleY(lastValidTransform.scaleY);
          node.x(lastValidTransform.x + node.width() / 2);
          node.y(lastValidTransform.y + node.height() / 2);
          node.getLayer().batchDraw();

          clearResolutionWarningType();

          onTransformEnd({
            target: node,
            isLowResolution: false,
          });
        }
      }
    }
  }, [showResolutionWarningModal, id, activeResizeImageId]);

  useEffect(() => {
    const img = new window.Image();
    img.crossOrigin = 'anonymous';
    img.src = src;
    img.onload = () => {
      setImage(img);
    };
  }, [src]);

  useEffect(() => {
    if (isSelected && trRef.current && imageRef.current) {
      trRef.current.nodes([imageRef.current]);
      trRef.current.getLayer().batchDraw();
    }
  }, [isSelected]);

  const onElementDragMove = useCallback(() => {
    if (selectedId !== id) {
      setSelectedId(id);
    }
  }, [id, selectedId, setSelectedId]);

  const debouncedDragMove = debounce(onElementDragMove, 500);

  const isDraggable = currentEditStage === 'image';

  const shouldApplyBrightness =
    currentEditStage === 'whiteInk' && isWhiteInkActive;

  const debouncedCheckBounds = useCallback(
    debounce((x, y, width, height) => {
      const actualWidth = width * Math.abs(scaleX);
      const actualHeight = height * Math.abs(scaleY);
      const isOutOfBounds =
        x < 0 ||
        y < 0 ||
        x + actualWidth > size.width ||
        y + actualHeight > size.height;
      setIsAnyElementOutOfBounds(isOutOfBounds);
    }, 100),
    [setIsAnyElementOutOfBounds, scaleX, scaleY, size.width, size.height],
  );

  useEffect(() => {
    return () => {
      debouncedCheckBounds.cancel();
    };
  }, [debouncedCheckBounds]);

  useEffect(() => {
    if (imageRef.current) {
      if (shouldApplyBrightness) {
        imageRef.current.cache({
          pixelRatio: 2,
          imageSmoothingEnabled: true,
          imageSmoothingQuality: 'high',
        });
      } else {
        imageRef.current.clearCache();
      }
      imageRef.current.getLayer().batchDraw();
    }
  }, [shouldApplyBrightness, image]);

  return (
    <Image
      {...imageProps}
      id={`image-${id}`}
      ref={imageRef}
      image={image}
      filters={shouldApplyBrightness ? [Konva.Filters.Brighten] : []}
      brightness={shouldApplyBrightness ? -1 : 0}
      onClick={isDraggable ? onSelect : undefined}
      onTap={isDraggable ? onSelect : undefined}
      draggable={isDraggable}
      rotation={rotation}
      scaleX={scaleX}
      scaleY={scaleY}
      offsetX={imageProps.width / 2}
      offsetY={imageProps.height / 2}
      x={imageProps.x + imageProps.width / 2}
      y={imageProps.y + imageProps.height / 2}
      onDragMove={(e) => {
        debouncedDragMove();
        const node = e.target;
        debouncedCheckBounds(
          node.x() - node.width() / 2,
          node.y() - node.height() / 2,
          node.width(),
          node.height(),
        );
      }}
      onDragEnd={(e) => {
        onTransformEnd({
          target: e.target,
          isLowResolution: imageProps?.isLowResolution || false,
          lowResolutionType: imageProps?.lowResolutionType || null,
        });
        setIsAnyElementOutOfBounds(false);
      }}
      onTransformEnd={handleTransformEnd}
    />
  );
};

export default URLImage;
