import React from 'react';
import styled from 'styled-components';
import {LeftOutlined} from '@ant-design/icons';

const PanelHeaderContainer = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-bottom: 24px;
  h2 {
    font-size: 20px;
    margin-bottom: 0;
  }
  span {
    font-size: 24px;
    margin-right: 8px;
  }
`;

function PanelHeader({onClick, children, showArrow = true}) {
  return (
    <PanelHeaderContainer onClick={onClick}>
      {showArrow && <LeftOutlined />}
      <h2>{children}</h2>
    </PanelHeaderContainer>
  );
}

export default PanelHeader;
