import React from 'react';
import styled from 'styled-components';
import {useDesignsStore} from '../../../Stores';
import {ReactComponent as ImageIcon} from '../Assets/icons/image.svg';
import {ReactComponent as TextIcon} from '../Assets/icons/text.svg';
import {ReactComponent as StickerIcon} from '../Assets/icons/sticker.svg';
import {ReactComponent as BackgroundIcon} from '../Assets/icons/background.svg';
import {ReactComponent as CutIcon} from '../Assets/icons/cut.svg';
import {ReactComponent as WhiteInkIcon} from '../Assets/icons/white-ink.svg';
import {Tooltip} from 'antd';
const appConfig = require('../../../data.json');

const toolBarItems = [
  {
    icon: ImageIcon,
    label: '圖片',
    key: 'image',
  },
  {
    icon: TextIcon,
    label: '文字',
    key: 'text',
    disabled: true,
    tooltip: 'Coming Soon',
  },
  {
    icon: StickerIcon,
    label: '貼圖',
    key: 'sticker',
    disabled: true,
    tooltip: 'Coming Soon',
  },
  {
    icon: BackgroundIcon,
    label: '背景',
    key: 'background',
    disabled: true,
    tooltip: 'Coming Soon',
  },
];

function ToolBar() {
  const {selectedToolbarItem, setSelectedToolbarItem} = useDesignsStore();
  return (
    <ToolBarContainer>
      {toolBarItems.map((item, index) => (
        <Tooltip title={item.tooltip}>
          <ToolBarItem
            key={index}
            isActive={selectedToolbarItem === item.key}
            disabled={item.disabled}
            onClick={() => !item.disabled && setSelectedToolbarItem(item.key)}>
            <item.icon />
            <span>{item.label}</span>
          </ToolBarItem>
        </Tooltip>
      ))}
    </ToolBarContainer>
  );
}

const ToolBarContainer = styled.ul`
  --active-bg-color: ${appConfig.designsTheme.primaryBG};
  --active-text-color: ${appConfig.designsTheme.primary};

  position: absolute;
  bottom: 24px;
  margin: auto;
  display: inline-flex;
  align-items: center;
  background-color: #fff;
  padding: 8px 16px;
  box-shadow: 1px 2px 10px 0px rgba(0, 0, 0, 0.1);
  border-radius: 16px;
  list-style: none;

  @media (max-width: 1441px) {
    bottom: 8px;
    padding: 4px 8px;
    border-radius: 8px;
  }
`;

const ToolBarItem = styled.li`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  column-gap: 16px;
  cursor: ${({disabled}) => (disabled ? 'not-allowed' : 'pointer')};
  opacity: ${({disabled}) => (disabled ? 0.5 : 1)};
  font-size: 14px;
  font-weight: 500;
  padding: 8px 16px;
  background-color: ${({isActive}) =>
    isActive ? 'var(--active-bg-color)' : 'transparent'};
  color: ${({isActive, disabled}) =>
    disabled ? '#A0A0A0' : isActive ? 'var(--active-text-color)' : '#25272c'};
  border-radius: 12px;
  transition: all 0.3s;
  svg {
    margin-bottom: 4px;
    fill: ${({isActive, disabled}) =>
      disabled ? '#A0A0A0' : isActive ? 'var(--active-text-color)' : '#25272c'};
  }

  @media (max-width: 1441px) {
    column-gap: 16px;
    font-size: 12px;
    padding: 4px 8px;
    border-radius: 8px;
    svg {
      margin-bottom: 0;
    }
  }
`;

export default ToolBar;
