import cv from '@techstark/opencv-js';

/**
 * 等待 OpenCV.js 載入完成
 * @returns {Promise} OpenCV.js 載入完成的 Promise
 */
export const waitForOpenCV = () => {
  return new Promise((resolve) => {
    if (cv.Mat) {
      resolve();
    } else {
      cv.onRuntimeInitialized = () => {
        resolve();
      };
    }
  });
};

/**
 * 建立臨時 Canvas 並將圖片繪製上去
 * @param {HTMLImageElement} image - 圖片元素
 * @returns {HTMLCanvasElement} 臨時 Canvas
 */
export const createTempCanvas = (image) => {
  const tempCanvas = document.createElement('canvas');
  const tempCtx = tempCanvas.getContext('2d');
  tempCanvas.width = image.width;
  tempCanvas.height = image.height;
  tempCtx.drawImage(image, 0, 0);
  return tempCanvas;
};

/**
 * 釋放 OpenCV Mat 資源
 * @param {Array<cv.Mat>} mats - 要釋放的 Mat 陣列
 */
export const releaseMats = (mats) => {
  mats.forEach((mat) => {
    if (mat && !mat.isDeleted()) {
      mat.delete();
    }
  });
};
