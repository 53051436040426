import React, {useState, useEffect} from 'react';
import styled from 'styled-components';
import {Modal, Button} from 'antd';
import {InfoCircleOutlined, CloseOutlined} from '@ant-design/icons';
import {navigate} from 'gatsby';
const appConfig = require('../data.json');

export default function ProductCustomization({
  visible,
  onCancel,
  product,
  config,
  designSpec,
  purchase,
  addToCart,
  specID,
  price,
  isLoggedIn,
}) {
  const [selectedOption, setSelectedOption] = useState('upload');
  const [isLargeScreen, setIsLargeScreen] = useState(true);

  useEffect(() => {
    const checkScreenSize = () => {
      setIsLargeScreen(window.innerWidth >= 1280);
    };

    checkScreenSize();
    window.addEventListener('resize', checkScreenSize);

    return () => window.removeEventListener('resize', checkScreenSize);
  }, []);

  const handleOptionSelect = (option) => {
    if (!isLoggedIn || (!isLargeScreen && option === 'editor')) {
      return;
    }
    setSelectedOption(option);
  };

  return (
    <StyledModal
      title="選擇稿件製作方式"
      closeIcon={<CloseOutlined />}
      visible={visible}
      onCancel={onCancel}
      onClose={onCancel}
      footer={
        <Footer
          selectedOption={selectedOption}
          product={product}
          config={config}
          designSpec={designSpec}
          addToCart={addToCart}
          purchase={purchase}
          specID={specID}
          price={price}
        />
      }
      width={isLargeScreen ? '720px' : '100%'}>
      <Container>
        <ProductName>{product.name}</ProductName>
        {!isLargeScreen && (
          <MobileNotice>
            <InfoCircleOutlined style={{color: '#d78100', marginRight: 8}} />
            13吋以下裝置與手機版暫不開放線上編輯，如欲使用請切換電腦版，手機版即將開放，敬請期待。
          </MobileNotice>
        )}
        <OptionsContainer>
          <OptionCard
            selected={selectedOption === 'upload'}
            onClick={() => handleOptionSelect('upload')}>
            <OptionIcon>
              <img src="/images/upload.svg" alt="上傳自定義設計" />
            </OptionIcon>
            <OptionTitle>上傳自定義設計</OptionTitle>
            <OptionDescription>
              {isLargeScreen && (
                <InfoCircleOutlined
                  style={{
                    color: '#6b7280',
                    marginRight: 8,
                    wordBreak: 'break-all',
                  }}
                />
              )}
              {isLargeScreen
                ? '稿件於訂單成立後上傳'
                : '稿件於訂單成立後上傳，請依照商品完稿規範上傳檔案，包含 Adobe Illustrator、Photoshop、PDF 等影像格式，多個檔案請一起壓縮為 Rar/Zip 格式上傳。'}
            </OptionDescription>
            {isLargeScreen && (
              <OptionDescription style={{fontSize: 12}}>
                請依照商品完稿規範上傳檔案，包含 Adobe
                Illustrator、Photoshop、PDF 等影像格式，多個檔案請一起壓縮為
                Rar/Zip 格式上傳。
              </OptionDescription>
            )}
          </OptionCard>
          <OptionCard
            disabled={!isLoggedIn || !isLargeScreen}
            selected={selectedOption === 'editor'}
            onClick={() => handleOptionSelect('editor')}>
            <OptionIcon>
              <img src="/images/editor.svg" alt="線上編輯設計" />
            </OptionIcon>
            <OptionTitle>線上編輯設計</OptionTitle>
            <OptionDescription>
              {isLargeScreen && (
                <InfoCircleOutlined
                  style={{color: '#6b7280', marginRight: 8}}
                />
              )}
              {isLoggedIn
                ? '線上完稿後加入購物車'
                : '登入後即可使用線上編輯功能'}
            </OptionDescription>
            <OptionDescription style={{fontSize: 12}}>
              Beta版 公開測試中
            </OptionDescription>
          </OptionCard>
        </OptionsContainer>
        <TipsContainer>
          <TipsTitle>
            <InfoCircleOutlined
              style={{marginRight: 4, color: appConfig.designsTheme.primary}}
            />
            客製化商品注意事項
          </TipsTitle>
          <ul>
            <li>請避免使用有版權圖片與不雅照片製作商品。</li>
            <li>個人化商品一旦成立訂單便無法修改設計樣式及訂單異動。</li>
            <li>此為客製化商品,不接受退換貨,請確認商品型號/款式再下單。</li>
            <li>
              訂購前請務必詳閱條款，如有違反本公司保留是否接受該訂單的權利。
            </li>
          </ul>
        </TipsContainer>
      </Container>
    </StyledModal>
  );
}

const Footer = ({
  selectedOption,
  product,
  config,
  designSpec,
  purchase,
  addToCart,
  specID,
  price,
}) => {
  if (selectedOption === 'upload') {
    return (
      <FooterContainer>
        <Price>
          <span>價格</span>
          NT$ {price} 元
        </Price>
        <StyledButton onClick={purchase} secondary compact>
          直接購買
        </StyledButton>
        <StyledButton onClick={addToCart} compact>
          加入購物車
        </StyledButton>
      </FooterContainer>
    );
  } else {
    return (
      <div>
        <StyledButton
          onClick={() => {
            navigate('/designs/', {
              state: {product: {...product, price}, config, designSpec, specID},
            });
          }}>
          開始設計
        </StyledButton>
      </div>
    );
  }
};

const StyledModal = styled(Modal)`
  &&&.ant-modal {
    @media (max-width: 768px) {
      margin: 0;
      padding: 0;
      max-width: 100%;
      top: 0;
    }
  }
  .ant-modal-close {
    color: black;
    top: 16px;
    right: 10px;
    @media (max-width: 768px) {
      left: 10px;
      right: unset;
    }
    .ant-modal-close-x {
      font-size: 24px;
    }
  }
  .ant-modal-content {
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    max-height: 800px;
    @media (max-width: 768px) {
      max-height: 100%;
    }
  }
  .ant-modal-header {
    padding: 24px 24px 0;
    background-color: #fff;
    border-radius: 16px 16px 0 0;
    border-bottom: none;

    .ant-modal-title {
      font-size: 24px;
      font-weight: 700;
      color: #000;
      line-height: 1.58;
      letter-spacing: 2px;
      @media (max-width: 768px) {
        text-align: center;
      }
    }
  }
  .ant-modal-body {
    padding: 24px;
    @media (max-width: 768px) {
      flex: 1;
      overflow-y: auto;
    }
  }
  .ant-modal-footer {
    padding: 20px 32px 24px;
    border-top: none;
    @media (max-width: 768px) {
      margin-top: 0;
      padding: 20px 24px 24px;
      border-top: 1px solid #f0f0f0;
      background: white;
      position: sticky;
      bottom: 0;
      z-index: 1;
    }
  }
  @media (max-width: 768px) {
    .ant-modal-content,
    .ant-modal-body,
    .ant-modal-footer {
      border-radius: 0;
    }
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

const OptionsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 24px;
  @media (max-width: 768px) {
    flex-direction: column;
    gap: 16px;
  }
`;

const ProductName = styled.div`
  font-size: 20px;
  font-weight: bold;
  color: #21272a;
`;

const OptionCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
  border: 1px solid
    ${(props) => (props.selected ? appConfig.colors.main : '#F1F1F1')};
  border-radius: 16px;
  padding: 20px;
  background-color: ${(props) => (props.selected ? '#F1FCF8' : '#fff')};
  min-height: 280px;
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  opacity: ${(props) => (props.disabled ? 0.7 : 1)};
  &:hover {
    border-color: ${(props) => (props.disabled ? '#F1F1F1' : '#00a884')};
  }
  @media (max-width: 768px) {
    position: relative;
    min-height: unset;
    width: 100%;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 16px;
  }
`;

const OptionIcon = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 12px;
  @media (max-width: 768px) {
    position: absolute;
    top: 18px;
    left: 16px;
    width: 24px;
    height: 24px;
  }
`;

const OptionTitle = styled.div`
  text-align: center;
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 8px;
  color: #1b1c1f;
  @media (max-width: 768px) {
    font-size: 16px;
    padding-left: 36px;
  }
`;

const OptionDescription = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 16px;
  color: #6b7280;
  @media (max-width: 768px) {
    padding-left: 36px;
    text-align: left;
  }
`;

const TipsContainer = styled.div`
  ul {
    list-style: auto;
    list-style-position: inside;
    margin-bottom: 0;
    li {
      font-size: 16px;
      color: #40444c;
    }
  }
`;

const TipsTitle = styled.h4`
  font-size: 16px;
  font-weight: 500;
  color: ${appConfig.designsTheme.primary};
  margin-bottom: 8px;
`;

const StyledButton = styled(Button)`
  &&.ant-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    border-radius: 10px;
    font-size: 16px;
    font-weight: 500;
    color: white;
    padding: 16px 0;
    background-color: ${appConfig.colors.main};
    border: none;
    height: auto;
    cursor: pointer;
    @media (max-width: 768px) {
      flex: 1;
    }
    ${(props) =>
      props.secondary &&
      `
      background-color: #F1FCF8;
      color: ${appConfig.colors.main};
    `}
    ${(props) =>
      props.compact &&
      `
      max-width: 112px;
      @media (max-width: 768px) {
        max-width: none;
      }
    `}
  }
`;

const Price = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  font-weight: 700;
  color: black;
  @media (max-width: 768px) {
    flex: 0 0 100%;
    font-size: 20px;
    justify-content: flex-start;
  }
  span {
    display: inline-block;
    font-size: 16px;
    font-weight: 500;
    margin-right: 8px;
    @media (max-width: 768px) {
      font-size: 14px;
    }
  }
`;

const FooterContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 16px;
  @media (max-width: 768px) {
    flex-wrap: wrap;
  }
`;

const MobileNotice = styled.div`
  padding: 12px;
  border-radius: 12px;
  background-color: #fff8eb;
  color: #d78100;
  font-size: 16px;
`;
