export const createCanvasSlice = (set, get) => ({
  groupSize: 600,
  canvasElements: [],
  selectedId: null,
  isAnyElementOutOfBounds: false,
  stageRef: null,
  designId: null,
  designData: null,
  product: null,
  config: null,
  productSpec: null,
  editorOption: null,
  history: [[]],
  currentHistoryIndex: 0,
  lastValidTransform: null,
  activeResizeImageId: null,
  setStageRef: (ref) => {
    if (ref?.current?.getStage()) {
      set({stageRef: ref});
    }
  },
  setProduct: async (product, app) => {
    set({product});
  },
  setConfig: (config) => set({config}),
  setSelectedId: (id) => set({selectedId: id}),
  setIsAnyElementOutOfBounds: (isOutOfBounds) =>
    set({isAnyElementOutOfBounds: isOutOfBounds}),
  addCanvasElement: (element) => {
    const img = new Image();
    img.src = element.src;
    img.onload = () => {
      set((state) => {
        const aspectRatio = img.width / img.height;
        const maxWidth = state.groupSize / 2;
        const width = Math.min(img.width, maxWidth);
        const height = width / aspectRatio;
        const x = (state.groupSize - width) / 2;
        const y = (state.groupSize - height) / 2;

        const newElement = {
          ...element,
          width,
          height,
          x,
          y,
          isLowResolution: element.isLowResolution || false,
          lowResolutionType: element.lowResolutionType || null,
        };

        const newElements = [...state.canvasElements, newElement];

        const newHistory = [
          ...state.history.slice(0, state.currentHistoryIndex + 1),
          newElements,
        ];

        return {
          canvasElements: newElements,
          history: newHistory,
          currentHistoryIndex: newHistory.length - 1,
        };
      });
    };
  },
  updateCanvasElement: (id, updates) => {
    set((state) => {
      const element = state.canvasElements.find((el) => el.id === id);
      if (!element) return state;

      const updatedElements = state.canvasElements.map((el) =>
        el.id === id ? {...el, ...updates} : el,
      );

      const {history, currentHistoryIndex} = state;
      const newHistory = history.slice(0, currentHistoryIndex + 1);
      newHistory.push(updatedElements);

      return {
        canvasElements: updatedElements,
        history: newHistory,
        currentHistoryIndex: newHistory.length - 1,
      };
    });
  },
  removeCanvasElement: (id) => {
    set((state) => {
      const updatedElements = state.canvasElements.filter((el) => el.id !== id);
      const newHistory = [
        ...state.history.slice(0, state.currentHistoryIndex + 1),
        updatedElements,
      ];

      return {
        canvasElements: updatedElements,
        history: newHistory,
        currentHistoryIndex: newHistory.length - 1,
      };
    });
  },
  setCanvasElements: (elements) => set({canvasElements: elements}),
  updateElementSrc: (id, newSrc) => {
    set((state) => ({
      canvasElements: state.canvasElements.map((el) =>
        el.id === id ? {...el, src: newSrc} : el,
      ),
    }));
  },
  copyElement: (id) =>
    set((state) => {
      const elementToCopy = state.canvasElements.find((el) => el.id === id);
      if (!elementToCopy) return state;

      const newElement = {
        ...elementToCopy,
        id: Date.now().toString(),
        x: elementToCopy.x + 10,
        y: elementToCopy.y + 10,
      };

      const updatedElements = [...state.canvasElements, newElement];
      const newHistory = [
        ...state.history.slice(0, state.currentHistoryIndex + 1),
        updatedElements,
      ];

      return {
        canvasElements: updatedElements,
        selectedId: newElement.id,
        history: newHistory,
        currentHistoryIndex: newHistory.length - 1,
      };
    }),
  getDesignData: () => {
    const state = get();
    return {
      canvasElements: state.canvasElements,
      uploadedImages: state.uploadedImages,
      isAnyElementOutOfBounds: state.isAnyElementOutOfBounds,
    };
  },
  restoreDesignData: (designData = {}) => {
    if (Object.keys(designData).length === 0) return;
    const {
      canvasElements = [],
      uploadedImages = [],
      isAnyElementOutOfBounds = false,
    } = designData?.config;
    set((state) => ({
      canvasElements: canvasElements.map((element) => ({
        ...element,
        type: element.type || 'image',
        x: element.x || 0,
        y: element.y || 0,
        width: element.width || 0,
        height: element.height || 0,
        rotation: element.rotation || 0,
        scaleX: element.scaleX || 1,
        scaleY: element.scaleY || 1,
      })),
      uploadedImages: uploadedImages,
      selectedId: null,
      isAnyElementOutOfBounds: isAnyElementOutOfBounds || false,
    }));
  },
  setDesignId: (id) => set({designId: id}),
  loadDesignById: async (id, app) => {
    try {
      if (!id) return;
      set({designId: id});
      const results = await app.actions.getDesignDataByID(id);
      get().restoreDesignData(results);
    } catch (error) {
      console.error('載入設計資料失敗:', error);
      throw error;
    }
  },
  setProductSpec: (spec) => set({productSpec: spec}),
  setEditorOption: (option) => set({editorOption: option}),
  undo: () => {
    const {history, currentHistoryIndex} = get();
    if (currentHistoryIndex > 0) {
      const newIndex = currentHistoryIndex - 1;
      const previousState = history[newIndex];

      set({
        canvasElements: previousState,
        currentHistoryIndex: newIndex,
      });
    }
  },
  redo: () => {
    const {history, currentHistoryIndex} = get();
    if (currentHistoryIndex < history.length - 1) {
      const newIndex = currentHistoryIndex + 1;
      const nextState = history[newIndex];

      set({
        canvasElements: nextState,
        currentHistoryIndex: newIndex,
      });
    }
  },
  getLowResolutionElements: () => {
    const state = get();
    if (!state?.canvasElements) return [];
    return (
      state.canvasElements.filter((element) => element?.isLowResolution) || []
    );
  },
  setLastValidTransform: ({id, transform}) =>
    set({
      lastValidTransform: transform,
      activeResizeImageId: id,
    }),
  clearLastValidTransform: () =>
    set({
      lastValidTransform: null,
      activeResizeImageId: null,
    }),
  updateGroupSize: (size) => set({groupSize: size}),
});
