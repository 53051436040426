import React, {useState} from 'react';
import styled, {css} from 'styled-components';
import ColorPicker from '../../ColorPicker';

const ColorContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const ColorTabs = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  column-gap: 24px;
  margin-bottom: 24px;
  width: 100%;
`;

const ColorTabsItem = styled.div`
  font-size: 16px;
  font-weight: 500;
  color: #000;
  cursor: pointer;
  padding-bottom: 8px;
  border-bottom: 1px solid transparent;
  transition: all 0.3s;
  ${({active}) =>
    active &&
    css`
      border-bottom: 1px solid #000;
    `}
`;

function Color() {
  const [selectedColor, setSelectedColor] = useState('#BF332B');
  const [currentTab, setCurrentTab] = useState('text');

  return (
    <ColorContainer>
      <ColorTabs>
        <ColorTabsItem
          active={currentTab === 'text'}
          onClick={() => setCurrentTab('text')}>
          文字顏色
        </ColorTabsItem>
        <ColorTabsItem
          active={currentTab === 'background'}
          onClick={() => setCurrentTab('background')}>
          文字底色
        </ColorTabsItem>
      </ColorTabs>
      <ColorPicker selectedColor={selectedColor} onClick={setSelectedColor} />
    </ColorContainer>
  );
}

export default Color;
