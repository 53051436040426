import React from 'react';
import styled from 'styled-components';
import {useDesignsStore} from '../../../Stores';
import {ReactComponent as LayerIcon} from '../Assets/icons/layer.svg';
import {ReactComponent as DeleteIcon} from '../Assets/icons/delete.svg';
import {ReactComponent as DragIcon} from '../Assets/icons/drag.svg';
import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  useSortable,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import {CSS} from '@dnd-kit/utilities';

const appConfig = require('../../../data.json');

const LayersWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: ${(props) =>
    props.isLayersOpen ? 'rgba(0,0,0,0.35)' : 'transparent'};
  pointer-events: ${(props) => (props.isLayersOpen ? 'auto' : 'none')};
  z-index: 99;
  transition: background 0.3s ease-in-out;
`;

const LayersContainer = styled.div`
  position: absolute;
  top: 0px;
  left: 0;
  bottom: 0;
  width: 254px;
  transition: transform 0.3s ease-in-out;
  transform: translateX(${(props) => (props.isOpen ? '0' : '-100%')});
  background-color: white;
  z-index: 100;
  box-shadow: ${(props) =>
    props.isOpen ? '4px 0 8px -4px rgba(0, 0, 0, 0.1)' : 'none'};
`;

const LayerHeader = styled.div`
  display: flex;
  align-items: center;
  padding: 24px;
  @media (max-width: 1441px) {
    padding: 16px;
  }
`;

const LayerHeaderIcon = styled.div`
  width: 48px;
  height: 48px;
  padding: 8px;
  border-radius: 8px;
  background-color: #f1fcf8;
  svg {
    color: ${appConfig.designsTheme.primary};
  }
  @media (max-width: 1441px) {
    width: 40px;
    height: 40px;
    padding: 8px;
  }
`;

const LayerTitle = styled.span`
  font-size: 20px;
  font-weight: 700;
  margin-left: 16px;
  color: #000;
  @media (max-width: 1441px) {
    font-size: 16px;
    margin-left: 12px;
  }
`;

const LayerList = styled.ul`
  list-style: none;
  padding: 0 12px;
  margin: 0;
  max-height: calc(100% - 96px);
  overflow-y: auto;
  @media (max-width: 1441px) {
    max-height: calc(100% - 72px);
  }
`;

const DragHandle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  cursor: grab;
  &:active {
    cursor: grabbing;
  }
`;

const LayerItem = styled.li`
  display: flex;
  align-items: center;
  padding: 12px;
  cursor: pointer;
  background-color: ${(props) => (props.selected ? '#f1fcf8' : 'transparent')};
  position: relative;
  z-index: ${(props) => props.style?.zIndex || 'auto'};
  border-radius: 12px;

  > div:not(:first-child) {
    width: 40px;
    height: 40px;
    border-radius: 8px;
    background-color: #f8f8f8;
    margin: 0 8px;
    overflow: hidden;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  p {
    font-size: 16px;
    font-weight: 500;
    color: #000;
    margin: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 90px;
  }
`;

const StyledDeleteIcon = styled(DeleteIcon)`
  margin-left: auto;
`;

function SortableItem({element, onSelect, onDelete, isSelected}) {
  const {attributes, listeners, setNodeRef, transform, transition} =
    useSortable({
      id: element.id,
    });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    zIndex: element.zIndex,
  };

  const handleDelete = (e) => {
    e.stopPropagation();
    onDelete(element.id);
  };

  return (
    <LayerItem
      ref={setNodeRef}
      style={style}
      selected={isSelected}
      onClick={() => onSelect(element.id)}>
      <DragHandle {...attributes} {...listeners}>
        <DragIcon />
      </DragHandle>
      <div>
        {element.type === 'image' && (
          <img src={element.src} alt="layer thumbnail" />
        )}
      </div>
      <p>{element.name}</p>
      <StyledDeleteIcon onClick={handleDelete} />
    </LayerItem>
  );
}

function Layers() {
  const {
    isLayersOpen,
    canvasElements,
    selectedId,
    setSelectedId,
    removeCanvasElement,
    setCanvasElements,
    setIsLayersOpen,
  } = useDesignsStore();

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );

  const handleDragEnd = (event) => {
    const {active, over} = event;

    if (active.id !== over.id) {
      const oldIndex = canvasElements.findIndex(
        (item) => item.id === active.id,
      );
      const newIndex = canvasElements.findIndex((item) => item.id === over.id);

      const newElements = arrayMove(canvasElements, oldIndex, newIndex);

      newElements.forEach((item, index) => {
        item.zIndex = index + 1;
      });
      setCanvasElements(newElements);
    }
  };

  const handleLayerSelect = (id) => {
    setSelectedId(id);
  };

  const handleLayerDelete = (id) => {
    setSelectedId(null);
    removeCanvasElement(id);
  };

  const handleOutsideClick = (e) => {
    if (e.target === e.currentTarget) {
      setIsLayersOpen(false);
    }
  };

  return (
    <LayersWrapper isLayersOpen={isLayersOpen} onClick={handleOutsideClick}>
      <LayersContainer isOpen={isLayersOpen}>
        <LayerHeader>
          <LayerHeaderIcon>
            <LayerIcon />
          </LayerHeaderIcon>
          <LayerTitle>編輯圖層</LayerTitle>
        </LayerHeader>
        <DndContext
          sensors={sensors}
          collisionDetection={closestCenter}
          onDragEnd={handleDragEnd}>
          <SortableContext
            items={canvasElements.map((item) => item.id)}
            strategy={verticalListSortingStrategy}>
            <LayerList>
              {canvasElements.map((element) => (
                <SortableItem
                  key={element.id}
                  element={element}
                  onSelect={handleLayerSelect}
                  onDelete={handleLayerDelete}
                  isSelected={selectedId === element.id}
                />
              ))}
            </LayerList>
          </SortableContext>
        </DndContext>
      </LayersContainer>
    </LayersWrapper>
  );
}

export default Layers;
