import {createToolbarSlice} from './toolbarSlice';
import {createCanvasSlice} from './canvasSlice';
import {createPreviewSlice} from './previewSlice';
import {createUploadSlice} from './uploadSlice';
import {createStageSlice} from './stageSlice';
import {createTransformSlice} from './transformSlice';
import {createAuthSlice} from './authSlice';

// 定義初始狀態
const initialState = {
  groupSize: 600,
  // Canvas 相關
  canvasElements: [],
  selectedId: null,
  isAnyElementOutOfBounds: false,
  stageRef: null,
  designId: null,
  designData: null,
  product: null,
  config: null,
  history: [[]],
  currentHistoryIndex: 0,

  // Preview 相關
  previewImage: null,
  previewConfig: null,
  isPreviewLoading: false,
  mergedImage: null,
  brightnessFilter: false,
  isUploading: false,
  screenShotUrl: null,

  // Stage 相關
  currentEditStage: 'image',
  hasMask: true,
  isSaveModalOpen: false,
  isWhiteInkActive: true,

  // Toolbar 相關
  selectedToolbarItem: null,
  isLayersOpen: false,
  isWarningDrawerOpen: false,

  // Upload 相關
  uploadedImages: [],
};

// 新增重置狀態的方法
const createResetSlice = (set) => ({
  resetStore: () => set(initialState),
});

export {
  createToolbarSlice,
  createCanvasSlice,
  createPreviewSlice,
  createUploadSlice,
  createStageSlice,
  createTransformSlice,
  createAuthSlice,
  createResetSlice,
  initialState,
};
