import React, {useContext, useState} from 'react';
import {Modal, Checkbox, Spin, message} from 'antd';
import Button from '../Widgets/Buttons';
import {useDesignsStore} from '../../../Stores';
import {Close} from 'styled-icons/material';
import styled from 'styled-components';
import BG from '../Assets/images/bg.png';
import {InfoCircleOutlined} from '@ant-design/icons';
import {Context} from '../../../AppContext';
import {navigate} from 'gatsby';
import {Link} from 'gatsby';
const appConfig = require('../../../data.json');

function SaveDesignModal() {
  const app = useContext(Context);
  const [isLoading, setIsLoading] = useState(false);
  const {
    isSaveModalOpen,
    setIsSaveModalOpen,
    mergedImage,
    getDesignData,
    captureAndUploadImages,
    designId,
    product,
    config,
    resetStore,
    screenShotUrl,
    whiteInkImageUrl,
    productSpec,
    handlePreviousStage,
    editorOption,
    getLowResolutionElements,
  } = useDesignsStore();

  const lowResolutionElements = getLowResolutionElements
    ? getLowResolutionElements()
    : [];

  const handleSave = async () => {
    if (isLoading) return; // 防止重複點擊
    setIsLoading(true);
    try {
      const {stageUrl, groupUrl} = await captureAndUploadImages(app);
      const designData = getDesignData();
      const hasWhiteInk = productSpec?.is_white_ink ?? true;

      const files = {
        canvas_image: stageUrl,
        print_image: groupUrl,
        die_cut_image: editorOption.die_cut_image,
      };

      if (hasWhiteInk) {
        files.white_ink_image = whiteInkImageUrl;
      }

      if (designId) {
        await app.actions.updateDesignData(designId, {
          config: designData,
          screenshotUrl: screenShotUrl,
          files,
        });
      } else {
        const {id} = await app.actions.createDesignData({
          config: designData,
          screenshotUrl: screenShotUrl,
          files,
        });

        await app.actions.addItem({
          product: product.id,
          config,
          price: product.price,
          design_id: id,
        });
      }

      setIsSaveModalOpen(false);
      resetStore();
      navigate(`/cart`);
    } catch (error) {
      console.error('儲存設計失敗:', error);
      message.error('儲存設計失敗');
    } finally {
      setIsLoading(false);
    }
  };

  const handleCloseModal = () => {
    handlePreviousStage();
  };

  return (
    <StyledModal
      title="預覽並保存"
      open={isSaveModalOpen}
      width="840px"
      centered
      footer={
        <Footer onConfirm={handleSave} loading={isLoading} product={product} />
      }
      onCancel={handleCloseModal}
      closeIcon={
        <Close
          style={{position: 'absolute', right: 20, top: 20}}
          size={20}
          color="#000"
          onClick={handleCloseModal}
        />
      }>
      <ModalContent>
        {lowResolutionElements.length > 0 && (
          <NoticeWrapper>
            <Notice>
              <InfoCircleOutlined style={{color: '#d78100', marginRight: 8}} />
              您的設計稿有 {lowResolutionElements.length}{' '}
              項錯誤，建議返回修正。因圖片解析度不足產生的印刷問題，不納入售後服務範圍。
            </Notice>
          </NoticeWrapper>
        )}
        <ProductName>{product?.name || '產品名稱'}</ProductName>
        <PreviewProductContainer>
          <img src={mergedImage || BG} alt="preview" />
          <p>
            預預覽圖僅供參考，成品可能有些微誤差，請勿作為印刷成品校色及比較之依據，同意確認後再下單
          </p>
        </PreviewProductContainer>
        <TipsContainer>
          <TipsTitle>
            <InfoCircleOutlined
              style={{marginRight: 4, color: appConfig.designsTheme.primary}}
            />
            客製化商品注意事項
          </TipsTitle>
          <ul>
            <li>請避免使用有版權圖片與不雅照片製作商品。</li>
            <li>個人化商品一旦成立訂單便無法修改設計樣式及訂單異動。</li>
            <li>此為客製化商品,不接受退換貨,請確認商品型號/款式再下單。</li>
            <li>
              訂購前請務必詳閱條款，如有違反本公司保留是否接受該訂單的權利。
            </li>
          </ul>
        </TipsContainer>
      </ModalContent>
    </StyledModal>
  );
}

const Footer = ({onConfirm, loading, product}) => {
  const [isAgreed, setIsAgreed] = useState(false);

  const handleConfirm = () => {
    if (!isAgreed) {
      message.warning('請先閱讀並同意條款');
      return;
    }
    onConfirm();
  };

  return (
    <FooterContainer>
      <CheckboxWrapper>
        <Checkbox
          checked={isAgreed}
          onChange={(e) => setIsAgreed(e.target.checked)}>
          我已閱讀並同意接受理想印製之
          <Link href="/editor_terms" target="_blank">
            線上編輯訂購條款及免責聲明
          </Link>
          詳列之所有內容。
        </Checkbox>
      </CheckboxWrapper>
      <PriceWrapper>
        <span>價格</span>
        <div>NT${product.price}</div>
      </PriceWrapper>
      <ButtonWrapper>
        <Spin spinning={loading}>
          <Button
            onClick={handleConfirm}
            primary
            loading={loading}
            disabled={!isAgreed}
            style={{padding: '16px 24px'}}>
            加入購物車
          </Button>
        </Spin>
      </ButtonWrapper>
    </FooterContainer>
  );
};

const StyledModal = styled(Modal)`
  .ant-modal-close {
    display: none;
  }
  .ant-modal-content {
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    max-height: 800px;
  }
  .ant-modal-header {
    padding: 24px 24px 16px;
    background-color: #fff;
    border-radius: 16px 16px 0 0;
    text-align: center;
    border-bottom: none;
    .ant-modal-title {
      font-size: 24px;
      font-weight: 700;
      color: #000;
      line-height: 1.58;
      letter-spacing: 2px;
    }
  }
  .ant-modal-body {
    padding: 0px 24px;
    flex: 1;
    overflow-y: auto;
  }
  .ant-modal-footer {
    padding: 24px;
    border-top: none;
  }
`;

const ModalContent = styled.div``;

const PreviewProductContainer = styled.div`
  text-align: center;
  margin-bottom: 24px;
  img {
    width: 320px;
    margin: 0 auto;
  }
  p {
    text-align: center;
    font-size: 14px;
    margin-top: 16px;
    color: #000;
  }
`;

const ProductName = styled.h3`
  font-size: 20px;
  font-weight: 700;
  color: #000;
  margin-bottom: 24px;
  text-align: center;
`;

const TipsContainer = styled.div`
  padding: 20px;
  ul {
    list-style: auto;
    list-style-position: inside;
    margin-bottom: 0;
    li {
      font-size: 16px;
      color: #40444c;
    }
  }
`;

const TipsTitle = styled.h4`
  font-size: 16px;
  font-weight: 500;
  color: ${appConfig.designsTheme.primary};
  margin-bottom: 8px;
`;

const FooterContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-wrap: wrap;
`;

const PriceWrapper = styled.div`
  display: flex;
  align-items: center;
  > span {
    font-size: 16px;
    font-weight: 500;
    color: #000;
    margin-right: 8px;
  }
  > div {
    display: flex;
    align-items: center;
    font-size: 24px;
    font-weight: 700;
    color: #000;
    margin-right: 16px;
  }
`;

const CheckboxWrapper = styled.div`
  flex: 100%;
  text-align: left;
  a {
    color: ${appConfig.designsTheme.primary};
    &:hover {
      text-decoration: underline;
    }
  }
  span {
    font-size: 16px;
    color: #000;
  }
`;

const ButtonWrapper = styled.div`
  .ant-spin-container {
    display: inline-block;
  }
  .ant-spin {
    max-height: unset;
  }

  button {
    &:disabled {
      background-color: #d9d9d9;
      border-color: #d9d9d9;
      color: rgba(0, 0, 0, 0.25);
      cursor: not-allowed;

      &:hover {
        background-color: #d9d9d9;
        border-color: #d9d9d9;
        color: rgba(0, 0, 0, 0.25);
      }
    }
  }
`;

const NoticeWrapper = styled.div`
  background-color: white;
  position: sticky;
  top: 0;
  padding-bottom: 4px;
`;

const Notice = styled.div`
  padding: 12px;
  border-radius: 12px;
  background-color: #fff8eb;
  color: #d78100;
  font-size: 16px;
  margin-bottom: 16px;
`;

export default SaveDesignModal;
