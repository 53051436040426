import React from 'react';
import styled from 'styled-components';
const appConfig = require('../../../../src/data.json');

const StyledButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 16px 0;
  border-radius: 12px;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.3s ease;
  min-width: 96px;
  border: none;
  background-color: ${appConfig.designsTheme.primaryBG};
  color: ${appConfig.designsTheme.primary};
  svg {
    margin-right: 8px;
    color: ${appConfig.designsTheme.primary};
  }
`;

const ActionButton = ({onClick, children, ...props}) => {
  return (
    <StyledButton onClick={onClick} {...props}>
      {children}
    </StyledButton>
  );
};

export default ActionButton;
